import axios from 'axios';
import { environment } from 'src/environments/environment';

export function getParentChain(chain: string) {
  chain = chain.toUpperCase();
  if (chain == "BTC" || chain == "DOGE" || chain == "LTC" || chain == "BTG" || chain == "BCH")
    return "UTXO"
  if (chain == "ETH" || chain == "BSC" || chain == "XDAI" || chain == "POLYGON" || chain == "EWC" || chain == "ARBITRUM" || chain == "AVALANCHE" || chain == "OPTIMISM" || chain == "FANTOM" || chain == "GETH" || chain == "ETHW" || chain == "XINFIN" || chain == "KLAY")
    return "EVM"
  if (chain == "XRP")
    return "XRPDROP"
  if (chain == "TRON")
    return "TRON"
  if (chain == "DOT")
    return "DOT"
  if (chain == "SOL")
    return "SOL"
  if (chain == "XLM")
    return "XLM"
    if (chain == "CARDANO")
    return "CARDANO"
  if (Object.keys(environment.cosmos_config).includes(chain)) {
    return environment.cosmos_config[chain].parentchain
  }
  if (Object.keys(environment.dot_config).includes(chain)) {
    return environment.dot_config[chain].parentchain
  }
  else
    return "MISC"
  // throw chain+" Chain not supported"
}

export async function getXRPAccountInformation(address: string): Promise<any> {
  var headers = {
    'Content-Type': 'application/json'
  };
  var dataString = '{"method":"account_info","params":[{"account":"' + address + '","strict":true,"ledger_index":"current","queue":true}]}';

  return await axios.post(environment.xrp_config.XRP.PUBLIC_SERVER, dataString, { headers: headers })

}
export type SIGNERS = {
  Account: string
  SigningPubKey: string
  TxnSignature: string | null
}