import { Pipe, PipeTransform } from '@angular/core';
import { toPretty } from 'src/app/shared/helpers/trezor/numberHelper';

@Pipe({
  name: 'amountrounded'
})
export class AmountroundedPipe implements PipeTransform {
  transform(input: any, args?: any): any {
    var exp,
      suffixes = ['K', 'M', 'G', 'T', 'P', 'E'];
    if (Number.isNaN(input)) {
      return null;
    }
    if (input < 1000) {
      return input;
    }
    exp = Math.floor(Math.log(input) / Math.log(1000));
    return (input / Math.pow(1000, exp)).toFixed(args) + suffixes[exp - 1];
  }
}


@Pipe({
  name: 'amountToPretty'
})
export class toPrettyAmount implements PipeTransform {
  transform(number: number, decimal: number): any {
    return toPretty(number,decimal);
  }
}