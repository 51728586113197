<div class="card card-border">
  <div class="card-body" [ngClass]="card_padding">
    <div class="align-self-center media-body">
      <div>
        <div class="d-flex">
          <i class="icon-address text-secondary" style="border-radius: 50%;padding: 12px;border: 2px solid #f7c325;display: flex;justify-content: center;font-size: 22px!important;align-items: center;width: 50px;height: 50px;"></i>
          <div class="details d-flex flex-column align-items-center px-2">
            <div class="col text-left p-0">
              <p class="m-0 truncate_Name">{{ option2.name }}</p>
            </div>
            <span class="text-wrap col truncate text-left p-0 t_Id text-break">{{ option2.condition}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>