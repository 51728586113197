<li id="CB_Create_Wallet" *ngIf="component_for == 'menu' && disable_New_Wallet" (click)="open(new_Group_Wallet)">
    <i class="icon-add-wallet mr-2"></i><span>New Wallet</span>
</li>
<button *ngIf="component_for == 'wallets' && disable_New_Wallet" (click)="open(new_Group_Wallet)"
    class="btn btn-round btn-primary"> <i class="icon-add-wallet mr-2"></i> New Wallet </button>
<a *ngIf="component_for == 'No Wallet'" (click)="open(new_Group_Wallet)" class="text-primary cursor-pointer">Create</a>
<div [ngClass]="{'disabled': !disable_New_Wallet }" [disabled]="!disable_New_Wallet" role="button"
    *ngIf="component_for == 'onboard'">
    <div class="row no-gutters justify-content-md-center">
        <button (click)="open(new_Group_Wallet)" class="btn btn-outline-green px-3 w-100"
            href="https://calendly.com/lmnl/liminal-meet" target="_blank">
            <h2 class="d-flex align-items-center justify-content-center py-1 m-0">
                <i class="icon-wallets mr-2 text-black"></i>
                <span class="onboarding_btn text-black">Create Wallet</span>
            </h2>
        </button>
    </div>
</div>
<ng-template #new_Group_Wallet let-modal>
    <div *ngIf="step_link_device">
        <div class="modal-header border-0">
            <div class="d-inline">
                <h2 class="modal-title">
                    <img class="w-40" src="../../../../assets/images/trezor.svg" /> Link Device
                </h2>
            </div>
            <button type="button" class="close float-right" aria-label="Close"
                (click)="modal.dismiss('Cross click');closeModal()">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="container-fluid">
                <div class="text-center">
                    <div class="mt-5 pt-3 link_Icon_Circle">
                        <img class="w-40" src="../../../../assets/images/trezor.svg" />
                    </div>
                </div>
                <div class="text-center mt-5 pt-3">
                    <h6 class="text-muted">Link Device</h6>
                    <span class="text-muted">Please link your hardware device to setup new wallet</span>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0">
            <app-my-account class="w-100 mx-2" [link_component_for]="link_component_for"></app-my-account>
        </div>
    </div>
    <div *ngIf="step_add_member">
        <div class="modal-header border-0">
            <div class="d-inline">
                <h2 class="modal-title">
                    <img class="w-40" src="../../../../assets/images/trezor.svg" /> Add Members
                </h2>
            </div>
            <button type="button" class="close float-right" aria-label="Close"
                (click)="modal.dismiss('Cross click');closeModal()">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="container-fluid">
                <div class="text-center">
                    <div class="mt-5 pt-3 link_Icon_Circle">
                        <img class="w-40" src="../../../../assets/images/trezor.svg" />
                    </div>
                </div>
                <div class="text-center mt-5 pt-3">
                    <h6 class="text-muted">Add Members</h6>
                    <sapn class="text-muted">Oops, looks like you do not have any members to join your cold wallet.
                        Please add members to your account so they can be part of your cold wallet. Please contact your
                        account manager to add members to your account.</sapn>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0">
            <button class="btn btn-outline-primary  w-100 mx-2" data-original-title="" title=""
                (click)="modal.dismiss('Ok click');" type="submit">
                Ok</button>
        </div>
    </div>
    <div *ngIf="pops_no==1">
        <div class="modal-header">
            <div class="d-inline">
                <h2 class="modal-title">
                    <i class="icon-wallet mr-2"></i>New Wallet
                </h2>
            </div>
            <button type="button" aria-label="Close" class="close" (click)="modal.dismiss('Cross click');closeModal()">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="container-fluid">
                <div class="mt-2 pb-3">
                    <div class="d-inline">
                        <label class="font-weight-bold text-muted">
                            Wallet Name
                        </label>
                    </div>
                    <input #verifyWalletName="ngModel" autocomplete="off" class="form-control" [(ngModel)]="walletName"
                        placeholder="Enter wallet name" type="text" maxlength="50" pattern="[a-zA-Z0-9 ]*">
                    <!-- <span *ngIf="verifyWalletName.invalid && (verifyWalletName.dirty || verifyWalletName.touched)" class="text-danger">Please enter the name in the valid format</span> -->
                </div>
                <div class="mt-2 pb-3">
                    <div class="d-flex font-weight-bold text-muted text-left">
                        <div class="w-100">Protocol</div>
                        <div class="px-2 flex-shrink-1"><label *ngIf="selected_Asset" class="link_style text-muted"
                                (click)="change_Asset()">Clear</label> </div>
                    </div>
                    <div ngbDropdown #myDrop="ngbDropdown" *ngIf="!selected_Asset">
                        <input autocomplete="off" class="form-control search-custom" [(ngModel)]="coin"
                            placeholder="Select protocol" ngbDropdownAnchor (focus)="myDrop.open()" type="text">
                        <ul ngbDropdownMenu class="digital_asset w-100 py-0">
                            <div *ngFor="let t of fltr.keys | keyvalue | search_coin: coin ">
                                <button class="dropdown-content card w-100 mb-0 p-0 brd_Box"
                                    (click)="select_Asset(t.key,t)">
                                    <li ngbDropdownItem class="p-3">
                                        <div class="align-items-center">
                                            <div class="media border-after-xs align-items-center">
                                                <div class="d-flex align-items-center box_Circle_Qr p-2">
                                                    <span class="network text-dark text-left  font-size-14"
                                                        [ngClass]="t.key.toLowerCase() "></span>
                                                </div>
                                                <div class="d-flex flex-column text-left">
                                                    <span #name class="m-0 truncate_Name font-size-14 ">{{
                                                        getName(t.key) }}</span>
                                                    <span class="text-muted  text-left  font-size-12">{{
                                                        getSupportedCoins(t.key)&& getSupportedCoins(t.key).length>0?
                                                        getName(t.key) +" and all supported tokens" : getName(t.key) |
                                                        titlecase}} </span>
                                                </div>
                                                <div class="media-body text-right">
                                                    <div class="position-relative d-flex justify-content-end ml-3">
                                                        <ul class="d-flex assets-group ml-2">
                                                            <li>
                                                                <span>
                                                                    <img class="img-fluid for-light img-round-border bg-white"
                                                                        [src]="t.key | getIcon" alt="">
                                                                </span>
                                                            </li>
                                                            <li
                                                                *ngIf="getSupportedCoins(t.key)&& getSupportedCoins(t.key).length>0">
                                                                <span
                                                                    *ngIf="getSupportedCoins(t.key)&& getSupportedCoins(t.key).length>0"
                                                                    class="mr-1 img-round-border last_count text-primary">
                                                                    + {{getSupportedCoins(t.key).length - 1}}
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </button>
                            </div>
                        </ul>
                    </div>
                    <div class="card card-border mb-0" *ngIf="selected_Asset">
                        <div class="card-body">
                            <div class="align-items-center">
                                <div class="media border-after-xs align-items-center">
                                    <div class="d-flex align-items-center box_Circle_Qr p-2">
                                        <span class="network text-dark text-left  font-size-14"
                                            [ngClass]="selected_asset_value.toLowerCase() "></span>
                                    </div>
                                    <div class="d-flex flex-column text-left">
                                        <span #name class="m-0 truncate_Name font-size-14 ">{{
                                            getName(selected_asset_value) }}</span>
                                        <span class="text-muted  text-left  font-size-12">{{
                                            getSupportedCoins(selected_asset_value)&&
                                            getSupportedCoins(selected_asset_value).length>0?
                                            getName(selected_asset_value) +" and all supported tokens" :
                                            getName(selected_asset_value) |
                                            titlecase}} </span>
                                    </div>
                                    <div class="media-body text-right">
                                        <div class="position-relative d-flex justify-content-end ml-3">
                                            <ul class="d-flex assets-group ml-2">
                                                <li>
                                                    <span>
                                                        <img class="img-fluid for-light img-round-border bg-white"
                                                            [src]="selected_asset_value | getIcon" alt="">
                                                    </span>
                                                </li>
                                                <li style="margin-top: 10px;"
                                                    *ngIf="getSupportedCoins(selected_asset_value)&& getSupportedCoins(selected_asset_value).length>0">
                                                    <span style="display: initial; padding: 8.5px;"
                                                        *ngIf="getSupportedCoins(selected_asset_value)&& getSupportedCoins(selected_asset_value).length>0"
                                                        class="mr-1 img-round-border last_count text-primary">
                                                        + {{getSupportedCoins(selected_asset_value).length - 1}}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-2 pb-3" *ngIf="selected_type?.name">
                    <span class="font-weight-bold text-muted" >Signer Type</span>
                    <div [ngClass]="{'disabled': !checkSignerSelection}" [disabled]="!checkSignerSelection" class="mb-4 mt-1" style="margin-left: auto !important;" ngbDropdown #myDropInitiator="ngbDropdown" >
                        <div class="d-flex test-2" >
                            <input autocomplete="off" [disabled]="!checkSignerSelection" class="form-control search-custom  test " id="dropdownManual"
                                ngbDropdownAnchor (focus)="myDropInitiator.open(); focusTotInit=true" (blur)="focusTotInit=false"
                                type="text" placeholder="Select Signer type" [value]="selected_type?.name" (keydown)="$event.preventDefault()"/>
                            <svg *ngIf="!focusTotInit && checkSignerSelection" (click)="myDropInitiator.open()" xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" style="color: gray;" class="bi bi-caret-down-fill iconCenter pr-2" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg>
                            <svg *ngIf="focusTotInit && checkSignerSelection" (click)="myDropInitiator.close()"  xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" style="color: gray;" class="bi bi-caret-up-fill iconCenter pr-2" viewBox="0 0 16 16">
                                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                            </svg>
                        </div>
                        <ul ngbDropdownMenu class="whitelistscroll py-0" >
                            <div class="d-flex " *ngFor="let data of walletType" style="border-bottom: 1px solid rgba(0, 0, 0, 0.15)">
                                <button class="dropdown-content card brd_Box p-0 m-0" style="width: 100%; border: none !important;"
                                    (click)="onDropDownBtnClick(data,'wallet_type')"
                                    [ngClass]="{'disable_dropdown': !isChainLinked(selected_asset_value) && data.name=='MULTI SIG'}">
                                    <li ngbDropdownItem class="p-3 text-wrap">
                                        <div class="d-flex  flex-column" >
                                            <div class="text-left text-margin">
                                                    {{data.name}}
                                            </div>
                                            <div class="ml-auto"></div>
                                        </div>
                                    </li>
                                </button>
                            </div>
                        </ul>
                    </div>
                </div>


                <div class="mt-2 pb-3" *ngIf="selected_type?.name === 'MULTI SIG'">
                    <div class="d-inline">
                        <label class="text-muted"><b>Group Members</b></label>
                    </div>
                    <div class="row mx-auto justify-content-between">
                        <div class=" col px-2 d-inline"><button [disabled]="!selected_asset_value"
                                [ngClass]="{'active': selectedItem == '1-2' || selected }" (click)="set_members('1-2')"
                                class="btn btn-outline-grey btn-group-member px-2  py-1 font-size-12 active w-100"
                                type="button" data-original-title="btn btn-outline-grey" title="">
                                <span>Quick</span></button>
                        </div>
                        <div class=" col px-2  d-inline"><button [disabled]="!selected_asset_value"
                                [ngClass]="{'active': selectedItem == '2-3'}" (click)="set_members('2-3')"
                                class="btn btn-outline-grey btn-group-member  px-2  py-1 font-size-12 w-100 active"
                                type="button" data-original-title="btn btn-outline-grey"
                                title=""><span>2/3</span></button></div>
                        <div class=" col px-2  d-inline text-center "><button
                                [disabled]="!selected_asset_value || (shield && selected_asset_value.toLowerCase() == 'tron')"
                                [ngClass]="{'active': selectedItem == '3-5'}" (click)="set_members('3-5')"
                                class="btn btn-outline-grey btn-group-member  w-100 px-2 py-1 font-size-12"
                                type="button" data-original-title="btn btn-outline-grey"
                                title=""><span>3/5</span></button></div>
                        <div class="col px-2  d-inline text-center"><button
                                [disabled]="!selected_asset_value || selected_asset_value.toLowerCase() == 'tron'"
                                [ngClass]="{'active': selectedItem == '4-7'}" (click)="set_members('4-7')"
                                class="btn btn-outline-grey btn-group-member w-100  px-2  py-1 font-size-12"
                                type="button" data-original-title="btn btn-outline-grey"
                                title=""><span>4/7</span></button></div>
                        <div class="col px-2  d-inline text-right"><button [disabled]="!selected_asset_value"
                                [ngClass]="{'active': selectedItem_custom == 'custom'}" (click)="custom_range()"
                                class="btn btn-outline-grey btn-group-member  w-100 px-2  py-1 font-size-12"
                                type="button" data-original-title="btn btn-outline-grey" title="">
                                <span> Custom </span>
                            </button></div>
                    </div>
                    <div class="mt-2 text-muted font-size-12" *ngIf="min_members == 1">
                        A minimum of {{min_members}} member out of the total {{total_members}} wallet members are
                        required to sign this transaction.
                    </div>
                    <div class="mt-2 text-muted font-size-12" *ngIf="min_members > 1">
                        A minimum of {{min_members}} members out of the total {{total_members}} wallet members are
                        required to sign this transaction.
                    </div>
                </div>



                <!-- <div class="mt-3" *ngIf="createWalletOption">
                    <div class="d-inline-flex ">
                        <label class=" text-muted"><b>Options</b></label>
                    </div>
                    <div class="d-flex mt-2">
                        <div class="d-table-cell">
                            <input [(ngModel)]="createSameAddress" class="checkbox_animated" id="chk-ani1" type="checkbox">
                          </div>
                        <div class="text-muted font-size-12">
                            Create wallets on Ethereum, BNB Chain & Polygon using <span class="text-black font-size-12">same address.</span>
                        </div>

                    </div>
                </div> -->


                <div class="mt-3" *ngIf="selected_asset_value?.toLowerCase() === 'dot'">
                    <div class="d-inline-flex ">
                        <label class=" text-muted"><b>Notes</b></label>
                    </div>
                    <div class=" mt-2">
                        <ol class="pl-3">
                            <li class="text-muted font-size-12 ">All members must have <span
                                    class="text-red font-size-12">minimum balance of 1 DOT</span> to create Polkadot
                                wallet.</li>
                            <li class="text-muted font-size-12 mt-1">When sending transactions the initiator needs to
                                have minimum reserve amount X DOT to initiate transaction.</li>
                            <li class="text-muted font-size-12 mt-1">Only initiator can cancel the transaction.</li>
                        </ol>

                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0">
            <button
                [disabled]="!walletName.trim() || (verifyWalletName.invalid && (verifyWalletName.dirty || verifyWalletName.touched)) || !selected_asset_value || !selected_Asset || !selected_type?.name"
                class="btn btn-outline-primary  w-100" data-original-title="" title="" (click)="onNextBtnClick()"
                type="submit">
                Next
            </button>
        </div>
    </div>

    <div *ngIf="custom && selected_type?.name === 'MULTI SIG'">
        <div class="modal-header">
            <div class="d-inline">
                <h2 class="modal-title">
                    <i class="icon-wallet mr-2"></i>Custom Group Members
                </h2>
            </div>
            <button type="button" aria-label="Close" class="close" (click)="modal.dismiss('Cross click');closeModal()">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="container-fluid">
                <div class="mt-2 pb-4">
                    <div class="text-muted">
                        Select total number of wallet members and minimum number of members required to sign
                        transaction.
                    </div>
                </div>
                <div class="mt-2 pb-1">
                    <div class="d-flex">
                        <div class="d-flex">
                            <label class="align-top mt-1 ml-1 text-muted"><b>Total Members</b></label>
                        </div>
                        <div class="d-flex float-right justify-content-end media-body">
                            <b class="mt-1 text-primary">{{min_members}}/{{total_members}}</b>
                        </div>
                    </div>
                    <div class="total_members">
                        <div class="mb-4 mx-1" ngbDropdown #myDrop="ngbDropdown" *ngIf="!selectedWallet">
                            <div class="d-flex test-2">
                                <input autocomplete="off" class="form-control search-custom  test " id="dropdownManual"
                                    ngbDropdownAnchor (focus)="myDrop.open(); focusTot=true" (blur)="focusTot=false"
                                    type="text" [value]="member_Value_drop" [(ngModel)]="member_Value_drop" />

                                <svg *ngIf="!focusTot" (click)="myDrop.open()" xmlns="http://www.w3.org/2000/svg"
                                    width="30" height="30" fill="currentColor"
                                    class="bi bi-chevron-down iconCenter pr-2" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                </svg>
                                <svg *ngIf="focusTot" (click)="myDrop.close()" xmlns="http://www.w3.org/2000/svg"
                                    width="30" height="30" fill="currentColor" class="bi bi-chevron-up iconCenter pr-2"
                                    viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                        d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
                                </svg>
                            </div>
                            <ul ngbDropdownMenu class="whitelistscroll w-100 py-0">
                                <div class="d-flex" *ngFor="let walletdata of numberList()" style="border-bottom: 0px solid rgba(0, 0, 0, 0.15)">
                                    <button class="dropdown-content card w-100 brd_Box p-0 m-0"
                                        (click)="selectMember(walletdata)">
                                        <li ngbDropdownItem class="pl-3 pr-2 pt-2 pb-2 text-wrap">
                                            <div class="d-flex align-items-center">
                                                <div class="text-left text-margin">
                                                    <div class="text-dark ">
                                                        {{walletdata.key}}
                                                    </div>
                                                </div>
                                                <div class="ml-auto"></div>
                                            </div>
                                        </li>
                                    </button>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="mt-2 pb-4">
                    <div class="d-flex">
                        <div class="d-flex">
                            <label class="align-top mt-1 ml-1 text-muted"><b>Minimum Approvals Required</b></label>
                        </div>
                    </div>
                    <div class="min_members">
                        <div class="mb-4 mx-1" ngbDropdown #myDropMin="ngbDropdown" *ngIf="!selectedWallet">
                            <div class="d-flex test-2">
                                <input autocomplete="off" class="form-control search-custom test" id="dropdownManual"
                                    ngbDropdownAnchor (focus)="myDropMin.open(); focusMin=true" (blur)="focusMin=false"
                                    type="text" [value]="minApprove_val" [(ngModel)]="minApprove_val" />
                                <svg *ngIf="!focusMin" (click)="myDropMin.open()" xmlns="http://www.w3.org/2000/svg"
                                    width="30" height="30" fill="currentColor"
                                    class="bi bi-chevron-down iconCenter pr-2" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                </svg>
                                <svg *ngIf="focusMin" (click)="myDropMin.close()" xmlns="http://www.w3.org/2000/svg"
                                    width="30" height="30" fill="currentColor" class="bi bi-chevron-up iconCenter pr-2"
                                    viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                        d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
                                </svg>
                            </div>
                            <ul ngbDropdownMenu class="whitelistscroll w-100 py-0">
                                <div class="d-flex" *ngFor="let data_min of minimalApprovalReq" style="border-bottom: 0px solid rgba(0, 0, 0, 0.15)">
                                    <button class="dropdown-content card w-100 brd_Box p-0 m-0"
                                        (click)="min_app_member(data_min);minApprove_val=data_min;min_members=data_min">
                                        <li ngbDropdownItem class="pl-3 pr-2 pt-2 pb-2 text-wrap">
                                            <div class="d-flex align-items-center">
                                                <div class="text-left text-margin">
                                                    <div class="text-dark ">
                                                        {{data_min}}
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </button>
                                </div>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0">
            <button class="btn btn-outline-primary " data-original-title="" title="" (click)="custom_members()"
                type="submit">
                Done </button>
        </div>
    </div>
    <div *ngIf="pops_no==2 && selected_type?.name === 'MULTI SIG'">
        <div class="modal-header">
            <div class="d-inline">
                <h2 class="modal-title">
                    <i class="icon-wallet mr-2"></i>
                    <span class="">New Cold Wallet</span>
                </h2>
            </div>
            <button type="button" aria-label="Close" class="close" (click)="modal.dismiss('Cross click');closeModal()">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body overflow-hidden">
            <div class="container-fluid">
                <div>
                    <div class="d-inline">
                        <label class="font-weight-bold text-muted">Members</label>
                    </div>
                    <div class="d-inline float-right">
                        <span class="text-muted" href="#">( {{this.getmem.length}}/{{total_members}} Selected )</span>
                    </div>
                    <ng-multiselect-dropdown [settings]="dropdownSettings" name="members"
                        placeholder="Select {{ total_members }} Members" [limitSelection]="total_members"
                        (onSelect)="onMemberSelect($event)" (onDeSelect)="onMemberDeSelect($event)" [data]="_members">
                        <ng-template #optionsTemplate let-item let-option="option" let-id="id"
                            let-isSelected="isSelected" class="d-flex ms-2">
                            <div *ngIf="getMembers[id].isDisabled" class="d-flex disabled"
                                [ngClass]="isSelected?'selected':''">
                                <div class="d-flex align-items-center box_Circle_Qr p-2">
                                    <img class="img-fluid for-light rounded-circle user-image "
                                        [src]="getMembers[id].phptoURL" alt="">
                                </div>
                                <div class="details d-flex flex-column pt-2">
                                    <div class="text-left p-0">
                                        <span #name class="m-0 truncate_Name">{{ getMembers[id].displayName }}</span>
                                    </div>
                                    <div class="truncate text-left p-0 t_Id">
                                        <span class="text-muted">Device is not linked</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!getMembers[id].isDisabled" class="d-flex" [ngClass]="isSelected?'selected':''">
                                <div class="d-flex align-items-center box_Circle_Qr p-2">
                                    <img class="img-fluid for-light rounded-circle user-image "
                                        [src]="getMembers[id].phptoURL" alt="">
                                </div>
                                <div class="details d-flex flex-column pt-2">
                                    <div class="text-left p-0">
                                        <span #name class="m-0 truncate_Name">{{ getMembers[id].displayName }}</span>
                                    </div>
                                    <div class="truncate text-left p-0 t_Id">
                                        <span class="text-muted">Device is linked</span>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template #optionSelectedTemplate let-option="option" let-id="id">
                            <div class="d-flex">
                                <div class="d-flex align-items-center box_Circle_Qr">
                                    <img class="img-fluid for-light rounded-circle-white-chip user-image mr-2"
                                        [src]="getMembers[id].phptoURL" alt="">
                                </div>
                                <div class="details d-flex align-items-center">
                                    <div class="col text-left p-0">
                                        <span #name class="m-0 truncate_Name ">{{ option }}</span>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-multiselect-dropdown>
                </div>
                <div class="mt-2 pb-4">
                    <div class="mt-2 text-muted">
                        Minimum {{min_members}} wallet members out of total {{total_members}} wallet members are
                        required to sign transaction.
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0">
            <button [disabled]="!enable_preivew" class="btn btn-outline-primary  w-100" data-original-title="" title=""
                (click)="onNextBtnClick()" type="submit">
                Next</button>
        </div>
    </div>
   
    <div *ngIf="pops_no==2 && selected_type?.name === 'Mobile (MPC)'">
        <div class="modal-header">
            <div class="d-inline">
                <h2 class="modal-title">
                    <i class="icon-wallet mr-2"></i>New Wallet
                </h2>
            </div>
            <button type="button" aria-label="Close" class="close" (click)="modal.dismiss('Cross click');closeModal()">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="container-fluid">
                <div style="display: flex; flex-direction: row; justify-content: space-between;">
                    <span class="ml-1 font-weight-bold text-muted">Team</span>
                    <add-team [component_for]="'new_wallet_team'" [orgProfile]="orgProfile"></add-team>
                </div>
               <div class="mb-4 mt-1" style="margin-left: auto !important; " ngbDropdown #myDropInitiator="ngbDropdown" >
                <div class="d-flex test-2" >
                    <input autocomplete="off" class="form-control search-custom  test " id="dropdownManual"
                        ngbDropdownAnchor (focus)="myDropInitiator.open(); focusTotInit=true" (blur)="focusTotInit=false"
                        type="text" placeholder="Select Team" [value]="selected_team.name" [(ngModel)]="searchTerm" />

                    <svg *ngIf="!focusTotInit" (click)="myDropInitiator.open()" xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" style="color: gray;" class="bi bi-caret-down-fill iconCenter pr-2" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                      </svg>
                      <svg *ngIf="focusTotInit" (click)="myDropInitiator.close()"  xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" style="color: gray;" class="bi bi-caret-up-fill iconCenter pr-2" viewBox="0 0 16 16">
                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                      </svg>
                </div>
                <ul ngbDropdownMenu class="whitelistscroll py-0" >
                    <div class="d-flex " *ngFor="let data of teamsListData | teamListSearch:searchTerm" style="border-bottom: 1px solid rgba(0, 0, 0, 0.15)">
                        <button class="dropdown-content card brd_Box p-0 m-0" style="width: 100%; border: none !important;"
                            (click)="onDropDownBtnClick(data,'list')">
                            <li ngbDropdownItem class="p-3 text-wrap">
                                <div class="d-flex  flex-column" >
                                    
                                    <div class="text-left text-margin">
                                            {{data.name}}
                                    </div>
                                    <div class="ml-auto"></div>
                                </div>
                            </li>
                        </button>
                    </div>
                </ul>

                
            </div>
            </div>
            <div class="test-2 p-2"  *ngIf="selected_team?.initiatorsList?.length>0 || selected_team?.signersList?.length>0">
                <ng-container *ngIf="selected_team?.initiatorsList?.length>0">
                    <div class="d-flex mb-2">
                        <span class="font-size-14  mr-2" style="align-self: center;">Initiators</span>
                            <div class="d-flex" [ngbTooltip]="'Initiators must first initiate the transaction request'" placement="right" style="align-self: center;"> 
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-circle text-center" style="align-self: center;" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                </svg>
                            </div>
                        <span class="ml-auto mt-2 mr-2 font-weight-bold text-muted">{{selected_team.initiatorConfig.split("of")[0] + " Of " + selected_team.initiatorConfig.split("of")[1]}}</span>
                    </div>
                    <div class="d-flex " style="flex-wrap: wrap; ">
                        <div class="mt-3 d-flex flex-row ml-2 selected-item selectedSuccessfully"  style="padding-right: 20px !important;" *ngFor="let item of isTeamMemberLinkDeviceSigner(selected_team?.initiatorsList,'initiator')">
                                <div class="d-flex align-items-center box_Circle_Qr">
                                    <img class="img-fluid for-light rounded-circle-white-chip user-image mr-2"
                                    src="{{item?.photoURL}}" alt="">
                                </div>
                                <div class="details d-flex align-items-center">
                                    <div class="col text-left p-0">
                                        <span #name class="m-0 truncate_Name ">{{item.displayName}}</span>
                                    </div>
                                </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="selected_team?.signersList?.length>0">
                    <div class="d-flex mb-2 mt-4">
                        <div class="d-flex">
                            <span class="font-size-14 mr-2" style="align-self: center;">Signers</span>
                            <div class="d-flex" [ngbTooltip]="'Signers can only sign the transaction after it is approved by initiators'" placement="right" style="align-self: center;"> 
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-circle text-center" style="align-self: center;" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                </svg>
                            </div>
                        </div>
                        <span class="ml-auto mt-2 mr-2 font-weight-bold text-muted">{{selected_team.signerConfig.split("of")[0] + " Of " + selected_team.signerConfig.split("of")[1]}}</span>
                    </div>
                    <div class="d-flex " style="flex-wrap: wrap; ">
                        <div class="mt-3 d-flex flex-row ml-2 selected-item" [ngClass]="'selectedSuccessfully'" style="padding-right: 20px !important;" *ngFor="let item of isTeamMemberLinkDeviceSigner(selected_team?.signersList,'signer')">
                                <div class="d-flex align-items-center box_Circle_Qr">
                                    <img class="img-fluid for-light rounded-circle-white-chip user-image mr-2"
                                    src="{{item?.photoURL}}" alt="">
                                </div>
                                <div class="details d-flex align-items-center">
                                    <div class="col text-left p-0">
                                        <span #name class="m-0 truncate_Name ">{{item.displayName}}</span>
                                    </div>
                                </div>
                        </div>
                    </div>
                </ng-container>

                
            </div>

    
        </div>
        <div class="modal-footer border-0">
            <!-- <div class="text-center mb-4" style="flex: auto;" *ngIf="selected_team?.name &&  notLinkedMemberSigner?.length > 0">
                <span class="mb-3  text-muted text-center" style="font-weight: 500;line-height: 21px;max-width: 80%;">Highlighted  {{notLinkedMemberSigner.length>0? 'Signers':''}} do not have Mobile Signer enabled</span>
            </div> -->
            <button
                [disabled]="  !selected_team?.name"
                class="btn btn-outline-primary  w-100" data-original-title="" title="" (click)="onNextBtnClick()"
                type="submit">
                Next
            </button>
        </div>
    </div>

    <div *ngIf="pops_no==3">
        <div class="modal-header">
            <div class="d-inline">
                <h2 class="modal-title">
                    <i class="icon-wallet mr-2"></i>New Wallet
                </h2>
            </div>
            <button type="button" aria-label="Close" class="close" (click)="modal.dismiss('Cross click');closeModal()">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="container-fluid">
                <div class="w-100 ml-1 font-weight-bold text-muted mb-1">Wallet</div>
                <div class="card card-border mb-4 border-radius" *ngIf="selected_Asset">
                    <div class="card-body">
                        <div class="align-items-center">
                            <div class="media border-after-xs align-items-center">
                                <div class="">
                                    <div class="position-relative d-flex justify-content-end ml-3">
                                        <ul class="d-flex assets-group ml-2">
                                            <li>
                                                <span>
                                                    <img class="img-fluid for-light img-round-border bg-white"
                                                        [src]="selected_asset_value | getIcon" alt="">
                                                </span>
                                            </li>
                                            <li style="margin-top: 10px;"
                                                *ngIf="getSupportedCoins(selected_asset_value)&& getSupportedCoins(selected_asset_value).length>0">
                                                <span style="display: initial; padding: 8.5px;"
                                                    *ngIf="getSupportedCoins(selected_asset_value)&& getSupportedCoins(selected_asset_value).length>0"
                                                    class="mr-1 img-round-border last_count text-primary">
                                                    + {{getSupportedCoins(selected_asset_value).length - 1}}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="pl-3">
                                    <div class="d-flex align-items-center box_Circle_Qr ">
                                        <span #name class="m-0 truncate_Name font-size-14 ">{{
                                            walletName }}</span>
                                    </div>
                                    <div class="d-flex flex-row text-left">
                                        <span class="network text-dark text-left  font-size-14"
                                            [ngClass]="selected_asset_value.toLowerCase() "></span>
                                        
                                        <span class="text-muted  text-left  font-size-12">{{
                                            getSupportedCoins(selected_asset_value)&&
                                            getSupportedCoins(selected_asset_value).length>0?
                                            getName(selected_asset_value) +" and all supported tokens" :
                                            getName(selected_asset_value) |
                                            titlecase}} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               <span class="ml-1 font-weight-bold text-muted mb-1"  *ngIf="selected_type?.name === 'Mobile (MPC)'">Team Name</span>
               <div class="mb-4 mx-1" style="margin-left: auto !important; " ngbDropdown #myDropInitiator="ngbDropdown"  *ngIf="selected_type?.name === 'Mobile (MPC)'">
                <div class="d-flex test-2" >
                    <input autocomplete="off" class="form-control search-custom  test " id="dropdownManual"
                        ngbDropdownAnchor [disabled]="true" (focus)="myDropInitiator.open(); focusTotInit=true" (blur)="focusTotInit=false"
                        type="text" [value]="selected_team.name" [(ngModel)]="selected_team.name" (keydown)="$event.preventDefault()"/>

                    
                </div>
                <ul ngbDropdownMenu class="whitelistscroll py-0" >
                    <div class="d-flex " *ngFor="let data of teamsListData" style="border-bottom: 1px solid rgba(0, 0, 0, 0.15)">
                        <button class="dropdown-content card brd_Box p-0 m-0" style="width: 100%; border: none !important;"
                            (click)="onDropDownBtnClick(data,'list')">
                            <li ngbDropdownItem class="p-3 text-wrap">
                                <div class="d-flex  flex-column" >
                                    
                                    <div class="text-left text-margin">
                                            {{data.name}}
                                    </div>
                                    <div class="ml-auto"></div>
                                </div>
                            </li>
                        </button>
                    </div>
                </ul>

                
              </div>
            </div>

            <div class="test-2 p-2" *ngIf="selected_type?.name === 'Mobile (MPC)'">
                <ng-container *ngIf="selected_team?.initiatorsList?.length>0">
                    <div class="d-flex mb-2">
                        <div class="d-flex">
                            <span class="font-size-14  mr-2" style="align-self: center;">Initiators</span>
                            <div class="d-flex" [ngbTooltip]="'Initiators must first initiate the transaction request'" placement="right" style="align-self: center;"> 
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-circle text-center" style="align-self: center;" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                </svg>
                            </div>
                        </div>
                        <span class="ml-auto mt-2 mr-2 font-weight-bold text-muted">{{selected_team.initiatorConfig.split("of")[0] + " Of " + selected_team.initiatorConfig.split("of")[1]}}</span>
                    </div>
                    <div class="d-flex " style="flex-wrap: wrap; ">
                        <div class="mt-3 d-flex flex-row ml-2 selected-item selectedSuccessfully" style="padding-right: 20px !important;" *ngFor="let item of selected_team?.initiatorsList">
                                <div class="d-flex align-items-center box_Circle_Qr">
                                    <img class="img-fluid for-light rounded-circle-white-chip user-image mr-2"
                                    src="{{item?.photoURL}}" alt="">
                                </div>
                                <div class="details d-flex align-items-center">
                                    <div class="col text-left p-0">
                                        <span #name class="m-0 truncate_Name ">{{item.displayName}}</span>
                                    </div>
                                </div>
                        </div>
                    </div>
                </ng-container>
                
                <ng-container *ngIf="selected_team?.signersList?.length>0">
                    <div class="d-flex mb-2 mt-4">
                        <div class="d-flex">
                            <span class="font-size-14 mr-2" style="align-self: center;">Signers</span>
                            <div class="d-flex" [ngbTooltip]="'Signers can only sign the transaction after it is approved by initiators'" placement="right" style="align-self: center;"> 
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-circle text-center" style="align-self: center;" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                </svg>
                            </div>
                        </div>
                        <span class="ml-auto mt-2 mr-2 font-weight-bold text-muted">{{selected_team.signerConfig.split("of")[0] + " Of " + selected_team.signerConfig.split("of")[1]}}</span>
                    </div>
                    <div class="d-flex " style="flex-wrap: wrap; ">
                        <div class="mt-3 d-flex flex-row ml-2 selected-item selectedSuccessfully" style="padding-right: 20px !important;" *ngFor="let item of selected_team?.signersList">
                                <div class="d-flex align-items-center box_Circle_Qr">
                                    <img class="img-fluid for-light rounded-circle-white-chip user-image mr-2"
                                    src="{{item?.photoURL}}" alt="">
                                </div>
                                <div class="details d-flex align-items-center">
                                    <div class="col text-left p-0">
                                        <span #name class="m-0 truncate_Name ">{{item.displayName}}</span>
                                    </div>
                                </div>
                        </div>
                    </div>
                </ng-container>

                
            </div>


            <div class="test-2 p-2 mt-4" *ngIf="selected_type?.name === 'MULTI SIG'">
                <div class="d-flex mb-2">
                    <span class="ml-2 mt-2 font-weight-bold text-muted">Signer</span>
                    <span class="ml-auto mt-2 mr-2 font-weight-bold text-muted">{{min_members}} of {{total_members}}</span>
                </div>
                <div class="d-flex " style="flex-wrap: wrap; ">
                    <div class="mt-3 d-flex flex-row ml-2 selected-item selectedSuccessfully" style="padding-right: 20px !important;" *ngFor="let item of getmem" >
                            <div class="d-flex align-items-center box_Circle_Qr">
                                <img class="img-fluid for-light rounded-circle-white-chip user-image mr-2"
                                src="{{item?.phptoURL}}" alt="">
                            </div>
                            <div class="details d-flex align-items-center">
                                <div class="col text-left p-0">
                                    <span #name class="m-0 truncate_Name ">{{item?.name}}</span>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="modal-footer border-0">
            <button
                class="btn btn-outline-primary  w-100" data-original-title="" title="" [disabled]="loading" (click)="!loading&&confirm_wallet();loading=true;"
                type="submit">
                Confirm
            </button>
        </div>
    </div>
    
    <div *ngIf="pops_no==4" class="modal-custom">
        <div class="modal-header border-0">
            <h2 class="modal-title">
                <i class="icon-wallet mr-2"></i> New Wallet
            </h2>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');"> <i
                    class="icon-close"></i> </button>
        </div>
        <div class="modal-body d-flex align-items-center bg-white">
            <div class="row">
                <div class="col-12">
                    <div class="mb-3 position-relative">
                        <div class="background_success text-center"> <img class="w-50"
                                src="../../../assets/images/check-outline-success.gif"> </div>
                    </div>
                    <div class="mb-5 text-center">
                        <div class="h5">Success</div>
                        <div class="text-muted">Awesome! We have received your request to create new wallet. We
                            will let you know once it is ready to use. 🎉</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0">
            <button title="" type="submit" class="btn btn-outline-primary w-100"
                (click)="modal.dismiss('Cross click');"> Done </button>
        </div>
    </div>
</ng-template>