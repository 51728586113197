import { Injectable, OnInit } from '@angular/core';
import { Wallet } from '../entities/wallet'
import { HttpService } from './http.service';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { LocalForageService } from 'src/app/shared/services/localforage.service';
import { first } from 'rxjs/operators';
import localforage from 'localforage';
import { BehaviorSubject } from 'rxjs';
import { MessageRequestStatusAPIData } from '../entities/messageSigningRequest';

@Injectable({
  providedIn: 'root'
})
export class WalletserviceService {
  data = Array<string>()
  wallets: {};
  walletids: any
  // private viewWallet = new BehaviorSubject(true);
  // currentViewWallet = this.viewWallet.asObservable();
  constructor(private httpService: HttpService, private logger: LoggerService, private localForageService: LocalForageService
  ) {
    localforage.config({
      name: "liminal",
      storeName: "wallets"
    })
    this.localForageService.store.subscribe(o => {
      this.wallets = o;
      this.walletids = Object.keys(this.wallets);
    });
    this.localForageService.allData();
  }

  private messageSignObservable = new BehaviorSubject(<MessageRequestStatusAPIData>{});
  messageSignStatus = this.messageSignObservable.asObservable();

  private policyNotWhitelisted = new BehaviorSubject(<any>{});
  policyNotWhitelistedStatus = this.policyNotWhitelisted.asObservable();

  messageSignChangeStatus(status: MessageRequestStatusAPIData) {
      this.messageSignObservable.next(status);
  }

  policyNotWhitelistedChangeStatus(status: any) {
    this.policyNotWhitelisted.next(status);
}

  clearWallet() {
    localforage.clear();
  }

  setWallet(wallet: Wallet) {
    const key = wallet.id + "_" + wallet.coin?.toLowerCase();
    localforage.setItem(key, wallet);
    this.wallets[key] = wallet;
  }

  getWallet(walletid: string): Wallet {
    let data = this.wallets[walletid];
    return data;
  }

  getAllWallets(): Wallet[] {
    return Object.values(this.wallets);
  }

  getAllWalletsForAdmin() {
    let walletids = Array<string>()
    let wallets = Array<Wallet>();
    // let res = this.httpService.getAllWalletsForAdmin()
    this.httpService.getAllWalletsForAdmin().pipe(first()).subscribe(allWalletsStr => {

      if (allWalletsStr && allWalletsStr.length > 1) {
        // check if for lowercase
        walletids = JSON.parse(JSON.stringify(allWalletsStr));
      }
      for (let i = 0; i < walletids.length; i++) {
        try {
          wallets.push(this.getWallet(walletids[i]));

        } catch (error) {
          console.error(error);
          this.logger.error(error, error)
        }

      }
      localforage.setItem("wallets_data", wallets);
    })
    return wallets;
  }

  // changeViewWallet(View: any) {
  //   this.viewWallet.next(View);
  // }
}