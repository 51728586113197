import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConstantService {

  private org = new BehaviorSubject([]);
  currentOrg = this.org.asObservable();

  changeOrg(org: any) {
    this.org.next(org);
  }

  constructor() {}
}
