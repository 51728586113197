import { environment } from "src/environments/environment";
let msigObj = environment.MPC_Protocol_Support;

export function csvGeneration(data, keys, csv_name) {
  let array = data;
  let str = '';
  let row = keys.toString();
  str += row + '\r\n';
  for (let i = 0; i < array.length; i++) {
    let line = Object.values(array[i]).toString();
    str += line + '\r\n';
  }
  let blob = new Blob(['\ufeff' + str], { type: 'text/csv;charset=utf-8;' });
  let dwldLink = document.createElement("a");
  let url = URL.createObjectURL(blob);
  let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
  if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
    dwldLink.setAttribute("target", "_blank");
  }
  dwldLink.setAttribute("href", url);
  dwldLink.setAttribute("download", csv_name + ".csv");
  dwldLink.style.visibility = "hidden";
  document.body.appendChild(dwldLink);
  dwldLink.click();
  document.body.removeChild(dwldLink);
}

export function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function capitalizeFirstLetter(name) {
  return name.replace(/^./, name[0].toUpperCase())
}

export function filterMultisigChains(chain): boolean {
  if (!msigObj[chain]) {
    return true;
  } else {
    let isMultiSig = Object.entries(msigObj).some(
      ([key, value]) => key === chain && value.signerType.includes("MULTI SIG")
    );
    return isMultiSig;
  }
}

export function removeEmpty(obj) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') {
      removeEmpty(obj[key]);
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key];
      }
    } else if (obj[key] === null || obj[key] === undefined) {
      delete obj[key];
    }
  });
  return obj;
}

export function decimalPlaces(num) {
  var match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
  if (!match) { return 0; }
  return Math.max(
    0,
    // Number of digits right of decimal point.
    (match[1] ? match[1].length : 0)
    // Adjust for scientific notation.
    - (match[2] ? +match[2] : 0));
}

export function txAmountValidation(val: number, balance: any) {
  const numericVal = Number(val);
  const numericBalance = Number(balance);
  const isValDefined = typeof val !== 'undefined';

  if (isValDefined && numericVal <= numericBalance) {
    if (numericVal < 0.00001) {
      return {
        allowedDecimals: false,
        invalidAmmount: true
      };
    } else {
      return {
        allowedDecimals: decimalPlaces(val) > 5,
        invalidAmmount: false
      };
    }
  } else {
    return {
      allowedDecimals: false,
      invalidAmmount: true
    };
  }
}