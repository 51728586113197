export async function getCurrentApp(transports) {
  try {
    let r = await transports.send(0xb0, 0x01, 0x00, 0x00);
    let i = 0;
    const format = r[i++];

    if (format !== 1) {
      return {
        name: "unknown",
        version: "version",
        flags: "flags",
      };
    }

    const nameLength = r[i++];
    const name = r.slice(i, (i += nameLength)).toString("ascii");
    const versionLength = r[i++];
    const version = r.slice(i, (i += versionLength)).toString("ascii");
    const flagLength = r[i++];
    const flags = r.slice(i, (i += flagLength));
    return {
      name,
      version,
      flags,
    };
  } catch (error) {
    return {
      name: "unknown",
      version: "version",
      flags: "flags",
    };
  }
}

export async function getInstalledApp(transports) {
  console.log("getInstalledApp");
  console.log(transports);
  let currentapp = await getCurrentApp(transports);
  await transports.send(0xb0, 0xa7, 0x00, 0x00);
  await this.delay(3000);
  this.linking_Ledger_Error = true;
  this.linking_Ledger_Message = "Allow listing application on Ledger";

  const payload = await transports.send(0xe0, 0xde, 0, 0);
  console.log("payload....", payload);
  const apps: Array<{
    name: string;
    hash: string;
    hashCodeData: string;
    blocks: number;
    flags: number;
  }> = [];
  let data = payload;

  // more than the status bytes
  while (data.length > 2) {
    if (payload[0] !== 0x01) {
      throw new Error("unknown listApps format");
    }
    let i = 1;

    while (i < data.length - 2) {
      const length = data[i];
      i++;
      const blocks = data.readUInt16BE(i);
      i += 2;
      const flags = data.readUInt16BE(i);
      i += 2;
      const hashCodeData = data.slice(i, i + 32).toString("hex");
      i += 32;
      const hash = data.slice(i, i + 32).toString("hex");
      i += 32;
      const nameLength = data[i];
      i++;

      if (length !== nameLength + 70) {
        throw new Error("invalid listApps length data");
      }

      const name = data.slice(i, i + nameLength).toString("ascii");
      i += nameLength;
      apps.push({
        name,
        hash,
        hashCodeData,
        blocks,
        flags,
      });
    }

    // continue
    data = await transports.send(0xe0, 0xdf, 0, 0);
  }
  return apps;
}