import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { AuthServiceJWT } from './shared/services/auth.service';
import { HttpService } from '../app/shared/services/http.service';
import { WalletserviceService } from '../app/shared/services/walletservice.service';
import { setUserProfile, updateUserDisplayName } from './shared/helpers/user.utils';
import { SegmentService } from 'ngx-segment-analytics';
@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {
  constructor(private segment: SegmentService, private logger: LoggerService, private router: Router, private authService: AuthServiceJWT, private httpService: HttpService, private walletService: WalletserviceService) {
  }
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {

    if (!this.authService.isLoggedIn) {
      this.router.navigateByUrl('/login');
      return false;
    } else {
      
      let user = this.authService.getUser;
      if (!user.emailVerified) {
        if (window.location.pathname === '/verify-email') {
          return true;
        } else {
          window.location.href = "/verify-email";
        }
      }

      let profile = await this.httpService.getprofile().toPromise();
      const storageUserExists = Boolean(localStorage.getItem('userProfile'));
      if (!storageUserExists) {
        setUserProfile(profile, user);
      }

      let organizations = profile['organizations'];

      if (organizations.length > 0) {
        if (window.location.pathname === '/verify-email') {
          this.router.navigate(['/']);
        }
        return true;
      } else {
        let userPendingOrgReq = await this.httpService.getAllPendingUserReq().toPromise();
        if (!userPendingOrgReq.length) {
          this.router.navigate(['/restricted-access']);
          return true;
        }

        let { success, data, message } = await this.httpService.acceptDeclineOrg(userPendingOrgReq[0].request.id, "1").toPromise();
        if (success == true) {
          this.segment.track("signup-success", userPendingOrgReq[0]);
          this.segment.track("invitation-accepted", userPendingOrgReq[0]);
          // Update user display name
          if (data) updateUserDisplayName(data.displayName);

          if (window.location.pathname === '/verify-email') {
            this.router.navigate(['/']);
          }
          return true;
        } else {
          alert(message);
        }
      }
    }
  }
}