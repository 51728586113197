import { IAuthUserType } from "../entities/User";

export const setUserProfile = (userInfo, user: IAuthUserType) => {
  const storageUser = localStorage.getItem('userProfile');
  const storageUserExists = Boolean(storageUser);

  let traits = Object.assign(
    storageUserExists ? JSON.parse(storageUser) : {},
    {
      userId: userInfo.id || "",
      created_at: userInfo["created_at"]?.toString() || "",
      lastLoginAt: userInfo["lastLoginAt"]?.toString() || "",
      phoneNumber: userInfo["phoneNumber"] || "",
      email: userInfo["email"] || "",
      name: userInfo["displayName"] || "",
      avatar: userInfo["photoURL"] || "",
      photoURL: userInfo["photoURL"] || "",
      emailVerified: user?.email_verified,
      type: userInfo["organizations"] ? userInfo["organizations"][0]?.type || "" : "",
    });
  localStorage.setItem("userProfile", JSON.stringify(traits));
  return traits;
};

export const getUserProfile = () => {
  const userProfileString = localStorage.getItem("userProfile");

  if (userProfileString) {
    return JSON.parse(userProfileString);
  }

  return null;
}

/*
   Overrides the user name of user profile
*/
export const updateUserDisplayName = (name: string) => {
  let user = getUserProfile();
  if (user) {
    user = { ...user, name }
    localStorage.setItem("userProfile", JSON.stringify(user));
  }

}
