import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { AuthServiceJWT } from './auth.service';
import { Observable } from 'rxjs/Observable';
import { Subject, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthServiceJWT) {

  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // expire token one hr before it actually expires
    if (new Date().getTime() > (this.auth.idTokenExpiry - (1000 * 60 * 60))) {
      console.error("token expired, logout");
      throw Error("jwt expired");
    }
    let authHeader = request.headers.get("Authorization");
    const noHeader = request.headers.get("NO-HEADER");
    if (noHeader) {
      console.log("no headers required");
      request.headers.delete("NO-HEADERs");
    } else {
      if (!authHeader) {
        // get token and use it
        authHeader = `Bearer ${this.auth.getToken}`;
        request = request.clone({
          setHeaders: {
            Authorization: authHeader
          }
        });
      } else if (authHeader === "NA") {
        // use the one passed in request
        request.headers.delete("Authorization");
      }
      request.headers.append('Accept', 'application/json');
    }
    return next.handle(request);
  }
}  