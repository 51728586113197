import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { signMessage } from "../helpers/trezor/messageSigner";
import { BehaviorSubject } from 'rxjs';
import { AuthServiceJWT } from './auth.service';
const policyTypes = ["whitelist_address", "wallet_velocity", "wallet_threshold"];
interface ApiResponse {
    success: boolean,
    message: string
}
@Injectable({
    providedIn: 'root'
})
export class PolicyService {
    constructor(private HttpService: HttpService,private auth: AuthServiceJWT) { }

    async addPolicy(walletId, walletName, name, type, address, amount, timePeriod, device, policyRequest): Promise<ApiResponse> {
        try {
            const signResponse = await this.signPolicyMessage(walletName, type, address, amount, timePeriod, device, policyRequest);
            
            return new Promise((resolve, reject) => {
                this.auth.writeAccess("policies:add").subscribe((accessToken) => {
                  this.HttpService.addPolicy(walletId, name, type, "", signResponse.message, "",accessToken)
                    .toPromise()
                    .then((result) => {
                      resolve(result);
                    })
                    .catch((error) => {
                      reject(error);
                    });
                });
            });
        } catch (e) {
            console.error(e);
            return Promise.reject({ success: false, message: "message signing failed" });
        }
    }

    async approvePolicy(policiestxdata, type, address, timePeriod, device, policyRequest): Promise<ApiResponse> {
        try {
            const signResponse = await this.signPolicyMessage(policiestxdata.wallet.name, type, address ,policiestxdata.sub_type.toLowerCase() === 'enable_app'? policiestxdata.name : policiestxdata.amount, timePeriod, device, policyRequest);
            return new Promise((resolve, reject) => {
                this.auth.writeAccess("policies:approve").subscribe((accessToken) => {
                //   console.log("accessToken-------------",accessToken)
                  this.HttpService.approvePolicy(policiestxdata.id, policiestxdata.wallet.id, "0x" + "", signResponse.message, "",accessToken)
                    .toPromise()
                    .then((result) => {
                      resolve(result);
                    })
                    .catch((error) => {
                      reject(error);
                    });
                });
            });
        } catch (e) {
            console.log(e);
            return Promise.reject({ success: false, message: "message signing failed" });
        }
    }

    async removePolicy(polictList, type, address, timePeriod, device, policyRequest): Promise<ApiResponse> {
        try {
            const signResponse = await this.signPolicyMessage(polictList.walletname, type, address, polictList.amount, timePeriod, device, policyRequest);
            return new Promise((resolve, reject) => {
                this.auth.writeAccess("policies:remove").subscribe((accessToken) => {
                //   console.log("accessToken-------------",accessToken)
                  this.HttpService.rempovePolicy(polictList.policyid, polictList.walletid, "0x" + "", signResponse.message, "",accessToken)
                    .toPromise()
                    .then((result) => {
                      resolve(result);
                    })
                    .catch((error) => {
                      reject(error);
                    });
                });
            });
        } catch (e) {
            console.error(e);
            return Promise.reject({ success: false, message: "message signing failed" });
        }
    }

    async signPolicyMessage(walletName, type, address, amount, timePeriod, device, policyRequest): Promise<any> {
        const today = (new Date).toISOString().slice(0, 10);
        let message = "";
        if (policyRequest === 'remove policy') {
            if (type === "whitelist_address") {
                message = "Remove " + address + " in " + walletName + " at " + today;
            } else if (type === "wallet_threshold") {
                message = "Remove transaction limit from wallet " + walletName + " at " + today;
            } else if (type === "wallet_velocity") {
                message = "Remove spending limit from wallet " + walletName + " at " + today;
            } else {
                return Promise.reject({ success: false, message: "invalid policy type" });
            }
            return {message:message};
        } else {
            if (type === "whitelist_address") {
                message = "Whitelist:" + address + " in " + walletName + " at " + today;
            } else if (type === "wallet_threshold") {
                message = "Update transaction limit to " + amount + " in " + walletName + " at " + today;
            } else if (type === "wallet_velocity") {
                message = "Update spending limit to " + amount + " per " + timePeriod + " in " + walletName + " at " + today;
            }
            else if (type === "enable_app") {
                message = "Enable appid " + address + " name " + amount + " in Trade at " + today;
            } else {
                return Promise.reject({ success: false, message: "invalid policy type" });
            }
            return {message:message};;
        }

    }

  
}