import { DOCUMENT } from '@angular/common';
import { NavService } from '../../services/nav.service';
import { Component, OnInit, Inject } from '@angular/core';
import { HttpService } from '../../../shared/services/http.service';
import { WalletserviceService } from '../../../shared/services/walletservice.service'
import { DataService } from 'src/app/shared/services/data.service';
import { Router } from '@angular/router';
// import { init } from 'commandbar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthServiceJWT } from '../../services/auth.service';
import { CreatedMessageRequest, CreatedMessageRequestData, MessageRequestStatus, MessageRequestStatusAPI, MessageRequestStatusAPIData } from '../../entities/messageSigningRequest';
import { orgType } from '../../entities/members';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public elem: any;
  orgProfile: any = {};
  orgType = orgType
  loading = true
  constructor(
    public navServices: NavService,
    @Inject(DOCUMENT) private document: any,
    private httpService: HttpService,
    private walletService: WalletserviceService,
    private data: DataService,
    public router: Router,
    private modalService: NgbModal,
    private authService: AuthServiceJWT,
  ) {
    this.getProfile().then(() => {
      this.loopRefresh();
      this.loopMessageSignTxn();
    });
  }

  loopMessageSignTxn() {
    let request: CreatedMessageRequestData = JSON.parse(this.authService.existingMessageSignTxn())
    if (request) {
      this.refreshMessagSignReq(request)
    }
    setTimeout(() => {
      this.loopMessageSignTxn()
    }, 10000);

  }

  async refreshMessagSignReq(request: CreatedMessageRequestData) {
    let getStatus: MessageRequestStatusAPI = await this.httpService.getMessageRequestStatus(request.wallet_id, request.identifier).toPromise()
    if (getStatus.success) {
      this.walletService.messageSignChangeStatus(getStatus.data)
    }
  }

  loopRefresh() {
    this.refreshPendingTx()
    setTimeout(() => {
      this.loopRefresh()
    }, 35000);
  }

  async refreshPendingTx() {
    let wallets = await this.httpService.getAllMultisigWallets().toPromise();
    this.walletService.clearWallet();
    for (let i = 0; i < wallets.length; i++) {
      this.walletService.setWallet(wallets[i]);
    }
    this.data.changeWallets(wallets);
    this.httpService.getAllPendingWalletTransactions().subscribe((txRequests) => {
      this.data.changePendingTxRequest(txRequests);
    })
    this.httpService.getTravelRulePending().subscribe((txRequests) => {
      this.data.changePendingTravelRuleTxRequest(txRequests);
    })
    this.httpService.getAllPendingMessageRequest().subscribe((txRequests) => {
      this.data.changePendingMessageSigningRequest(txRequests.data);
    })
    this.httpService.getTeamsPendingTxReq().subscribe((txRequests) => {
      this.data.changeMpcPendingTxRequest(txRequests.data);
    })
    this.httpService.getAllMultisigWalletTransactions().subscribe((txs) => {
      this.data.changeTxs(txs);
    })
    this.httpService.getAllPendingPoliciesTransactions().subscribe((txRequests) => {
      this.data.changePendingPolicyRequest(txRequests.data.policyList);
    })
    this.httpService.getprofile().subscribe((userDetailRequests) => {
      this.data.changeUserProfileRequest(userDetailRequests);
    })
    this.httpService.getStakingWalletList().subscribe((data) => {
      this.data.changeStakingWalletRequest(data.data);
    })
    this.httpService._getStakingWalletList().subscribe((data) => {
      this.data.changeStakingV2WalletRequest(data.data);
    })
    this.data.triggerRefresh.next('true');
     
    if (this.orgProfile.id) {
      this.httpService.getWalletReq(this.orgProfile.id).subscribe((txRequests) => {
        this.data.changeWalletRequest(txRequests.data);
      })
    }
  }
  ngOnInit() {
    this.elem = document.documentElement;
  }

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  async getProfile() {
    try {
      let profile = await this.httpService.getprofile().toPromise();
      this.orgProfile = profile['organizations'][0];
      this.loading = false
    } catch (err) {
      this.loading = false
    }

  }
}