import { ICore } from "@walletconnect/types";
import { environment } from "../../../../environments/environment";
import { utils } from "ethers";

// Walletconnect latest library cdn dependencies
declare const walletconnectweb3wallet: any;
declare const walletconnectCore: any;
declare const walletconnectUtils: any;

export let web3wallet: any;
export let core: ICore;

export async function createWeb3Wallet() {
  core = new walletconnectCore.Core({
    projectId: environment.walletConnect.PROJECT_ID,
  });

  web3wallet = await walletconnectweb3wallet.Web3Wallet.init({
    core,
    metadata: environment.walletConnect.metadata,
  });
}

export async function pair(params: { uri: string }) {
  return await core.pairing.pair({ uri: params.uri });
}

export async function disconnectSession() {
  let sessions: any = getActiveSessions();
  sessions.forEach(async ({ topic }) => {
    await web3wallet.disconnectSession({
      topic,
      reason: walletconnectUtils.getSdkError("USER_DISCONNECTED"),
    });
  });
}

export async function sendResponse(topic: string, response) {
  await web3wallet.respondSessionRequest({
    topic,
    response,
  });
}

/**
 * Converts hex to utf8 string if it is valid bytes
 */
export function convertHexToUtf8(value: string) {
  if (utils.isHexString(value)) {
    return utils.toUtf8String(value);
  }

  return value;
}

export function getActiveSessions() {
  return Object.values(web3wallet.getActiveSessions());
}

export function getActivePairings() {
  return web3wallet.engine.signClient.core.pairing.pairings.values;
}

export function getPeerMetaDataByTopic(topic: string) {
  let pairings = getActivePairings();
  return pairings.find((pair) => pair.topic === topic)?.peerMetaData;
}

export function getPeerMetaDataOfSession(session: any) {
  return session.peer.metadata;
}

export async function extendSession({ topic }: any) {
  await web3wallet.extendSession({ topic });
}

/**
 * Methods
 */
export const EIP155_SIGNING_METHODS = {
  PERSONAL_SIGN: "personal_sign",
  ETH_SIGN: "eth_sign",
  ETH_SIGN_TRANSACTION: "eth_signTransaction",
  ETH_SIGN_TYPED_DATA: "eth_signTypedData",
  ETH_SIGN_TYPED_DATA_V3: "eth_signTypedData_v3",
  ETH_SIGN_TYPED_DATA_V4: "eth_signTypedData_v4",
  ETH_SEND_TRANSACTION: "eth_sendTransaction",
};

export const signingTypes = [
  EIP155_SIGNING_METHODS.PERSONAL_SIGN,
  EIP155_SIGNING_METHODS.ETH_SIGN,
  EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA,
  EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V3,
  EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V4,
  EIP155_SIGNING_METHODS.ETH_SIGN_TRANSACTION,
];


export const allSupportedEIP155Methods = [
  EIP155_SIGNING_METHODS.PERSONAL_SIGN,
  EIP155_SIGNING_METHODS.ETH_SEND_TRANSACTION,
  EIP155_SIGNING_METHODS.ETH_SIGN,
  EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA,
  EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V3,
  EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V4,
  EIP155_SIGNING_METHODS.ETH_SIGN_TRANSACTION,
];

/**
 * Check if chain is part of EIP155 standard
 */
export function isEIP155Chain(chain: string) {
  return chain.includes("eip155");
}

/**
 * Check if chain is part of EIP155 standard
 */
export function isEIP_155signingMethod(method: string) {
  return signingTypes.includes(method);
}

export interface WalletConnectTransaction {
  walletConnect: {
    topic: string;
    id: number;
  };
  txRequestId: number;
  walletId: number;
}

export enum TransactionStatus {
  BROADCAST_COMPLETED = 2,
  COMPLETE = 4,
  REJECTED = 5,
}
