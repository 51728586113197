<!-- Page Sidebar Start-->
<div class="logo-icon-wrapper text-center">
  <a routerLink="/">
    <img class="img-fluid" src="assets/images/logo/logo-icon.png" alt="">
  </a>
</div>
<nav class="sidebar-main">
  <div id="sidebar-menu">
    <ul class="sidebar-links custom-scrollbar">
      <div class="simplebar-content p-0" *ngIf="loading">
        <li class="sidebar-list cursor-pointer ">
          <ngx-skeleton-loader></ngx-skeleton-loader>
        </li>
        <!-- <li class="sidebar-list cursor-pointer" routerLink="/wallets" *ngIf="active" routerLinkActive="active" (click)="checkWallet(true)">
          <a class="d-flex flex-column align-items-center text-dark"><i class="icon-wallets"></i> Wallets</a>
        </li>
        <li class="sidebar-list cursor-pointer" routerLink="/wallets" *ngIf="!active" (click)="checkWallet(true)">
          <a class="d-flex flex-column align-items-center text-dark"><i class="icon-wallets"></i> Wallets</a>
        </li> -->
        <li class="sidebar-list cursor-pointer">
          <ngx-skeleton-loader></ngx-skeleton-loader>
        </li>
        <li class="sidebar-list cursor-pointer">
          <ngx-skeleton-loader></ngx-skeleton-loader>
        </li>
       
       
        <!-- <li class="sidebar-list cursor-pointer py-3 my-3">
          <a (click)="logout()" class="d-flex flex-column align-items-center text-dark"><i class="icon-logout"></i> Logout</a>
        </li> -->
      </div>
      <div class="simplebar-content p-0" *ngIf="!loading">
        <li class="sidebar-list cursor-pointer" routerLink="/" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}">
          <a class="d-flex flex-column align-items-center text-dark"><i class="icon-home"></i> Home</a>
        </li>
        <!-- <li class="sidebar-list cursor-pointer" routerLink="/wallets" *ngIf="active" routerLinkActive="active" (click)="checkWallet(true)">
          <a class="d-flex flex-column align-items-center text-dark"><i class="icon-wallets"></i> Wallets</a>
        </li>
        <li class="sidebar-list cursor-pointer" routerLink="/wallets" *ngIf="!active" (click)="checkWallet(true)">
          <a class="d-flex flex-column align-items-center text-dark"><i class="icon-wallets"></i> Wallets</a>
        </li> -->
        <li class="sidebar-list cursor-pointer" [ngClass]="{'active': isWalletActive()}" >
          <a class="d-flex flex-column align-items-center text-dark" routerLink="/wallets"><i class="icon-wallets"></i>Wallets</a>
          <a class="d-none" routerLink="/wallets/refills"><i class="icon-dev"></i></a>
        </li>
        <li class="sidebar-list cursor-pointer" [ngClass]="{'active': isStakingActive()}" *ngIf="profile.orgType !== orgType.sub">
          <a class="d-flex flex-column align-items-center text-dark" routerLink="/wallets/staking" ><i class="icon-staking"></i> Staking</a>
          <a class="d-none" routerLink="/wallets/staking/calculator"><i class="icon-dev"></i></a>
          <a class="d-none" routerLink="/wallets/staking_legacy"><i class="icon-dev"></i></a>
        </li>
        <li class="sidebar-list cursor-pointer" routerLink="/nft-wallet" routerLinkActive="active" *ngIf="profile.orgType !== orgType.sub">
          <a class="d-flex flex-column align-items-center text-dark"><i class="icon-nft"></i> NFT</a>
        </li>
        <li class="sidebar-list cursor-pointer" routerLink="/tx" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}">
          <a class="d-flex flex-column align-items-center text-dark"><i class="icon-history"></i> History</a>
        </li>
        <li class="sidebar-list cursor-pointer" *ngIf="profile.type===2"  routerLink="/reports" routerLinkActive="active">
          <a class="d-flex flex-column align-items-center text-dark"><i class="icon-reports"></i> Reports</a>
        </li>
        <li class="sidebar-list cursor-pointer" routerLink="/apps" (click)="manipulateView()" routerLinkActive="active" *ngIf="profile.orgType !== orgType.sub && profile.type === 2">
          <a class="d-flex flex-column align-items-center text-dark"><i class="icon-apps"></i> Apps</a>
        </li>
        <ng-container *ngIf="!(profile.orgType == orgType.sub && profile.custodyType == custodyType.CUSTODY)">
          <li class="sidebar-list cursor-pointer" *ngIf="profile.type === 2" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <a class="d-flex flex-column align-items-center text-dark" routerLink="/dev/configure"><i class="icon-dev"></i> Dev</a>
            <!-- <a class="d-flex flex-column align-items-center text-dark" routerLink="/dev/protocols"><i class="icon-dev"></i> Dev</a> -->
            <a class="d-none" routerLink="/dev/configure"><i class="icon-dev"></i></a>
            <a class="d-none" routerLink="/dev/gas-station"><i class="icon-dev"></i></a>
          </li>
        </ng-container>
        <li class="sidebar-list cursor-pointer" routerLink="/settings" routerLinkActive="active">
          <a class="d-flex flex-column align-items-center text-dark"><i class="icon-settings"></i> Settings</a>
        </li>
        <!-- <li class="sidebar-list cursor-pointer py-3 my-3">
          <a (click)="logout()" class="d-flex flex-column align-items-center text-dark"><i class="icon-logout"></i> Logout</a>
        </li> -->
      </div>
    </ul>
  </div>
</nav>