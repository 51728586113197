import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { orgType, CUSTODY_TYPE } from './shared/entities/members';
import { DataService } from './shared/services/data.service';
import { HttpService } from './shared/services/http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrgAuthGuard implements CanActivate {
  blackListRouteCustody = [
    '/apps',
    '/nft-wallet',
    '/wallets/staking',
    '/wallets/staking_legacy',
    '/wallets/refills',
    '/wallets/staking/calculator',
    '/dev/configure',
    '/dev/gas-station',
    '/dev/consolidation',
    '/settings/wallet-key-card'
  ]
  blackListRouteNonCustody = [
    '/apps',
    '/nft-wallet',
    '/wallets/staking',
    '/wallets/staking_legacy',
    '/wallets/refills',
    '/wallets/staking/calculator',
    '/settings/wallet-key-card'
  ]
  orgType = orgType
  constructor(private router: Router, private data: DataService, private httpService: HttpService) {
  }
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    let profile = await this.httpService.getprofile().toPromise();
    console.log('profile', profile.displayName);

    if (this.blackListRouteCustody.includes(window.location.pathname) && profile['organizations'][0].orgType == orgType.sub && profile['organizations'][0].custodyType == CUSTODY_TYPE.CUSTODY) {
      this.router.navigate(['/']);
      return false;
    } else if (this.blackListRouteNonCustody.includes(window.location.pathname) && profile['organizations'][0].orgType == orgType.sub && profile['organizations'][0].custodyType == CUSTODY_TYPE.NONCUSTODY) {
      this.router.navigate(['/']);
      return false;
    } else if (profile.organizations[0].orgType === orgType.management) {
      window.location.href = environment.adminPanelUrl;
    }else {
      return true;
    }
  }
}