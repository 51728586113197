import { getNewWebUSBTransport as getNewTransport } from "./getTransports";
import Btc from '@ledgerhq/hw-app-btc';
import { PubKeys } from '../../entities/Pubkey';
import { getWalletType } from '../pathUtils';
import * as bip32path from 'bip32-path';
import TransportWebUSB from '@ledgerhq/hw-transport-webusb';
import { LedgerSigner } from '@cosmjs/ledger-amino';
import { makeCosmoshubPath } from '@cosmjs/amino';
import { toBase64 } from '@cosmjs/encoding';
import { ledgerLinkErrorHandler } from "./linkError";
import { getCurrentApp } from "./getLedgerApp";
import { environment } from "src/environments/environment";

export async function linkBCHLedger() {
  this.ng_bch = false;
  this.linking_Ledger_Error = true;
  this.linking_Ledger_Message = "Device link in progress..Please wait";
  let transport = await getNewTransport();
  try {
    const btc = new Btc(transport);
    let coinPath = 145;
    var CoinName = "Bitcoin Cash"
    let xpubVersion = 0x0488b21e; // Mainnet

    var resXpub = await btc.getWalletXpub({ path: "m/44'/" + coinPath + "'/0'", xpubVersion: xpubVersion });
    if (resXpub) {
      let wallet: PubKeys = { xpub: "", path: "", id: "", type: "", name: "", config: "" };
      wallet.xpub = resXpub;

      wallet.path = "m/44'/" + coinPath + "'/0'";
      wallet.config = '1of1';
      wallet.type = getWalletType(bip32path.fromString("m/44'/" + coinPath + "'/0'").toPathArray())
      wallet.id = wallet.xpub;
      wallet.coin = "BCH"
      await this.httpService.saveKey(wallet, 'ledger').toPromise()
    }
    else {
      this.coin_name = "BCH";
      this.error_message(this.coin_name)
    }

    try {
      this.segment.track("link-device-bch-success", this.authService.getUser)
        .then(() => console.log("Link Device BCH Success"));
      this.linked_coin.push("bch");

    } catch (err) {
      this.logger.error(err, err);
      console.log(err);
      // const xrp = new Xrp(transport);
      // let result = await xrp.getAddress(environment.xrp_config.XRP.path || "44'/144'/0'/0/0", false);
      // Prepare ledger
      const ledgerTransport = await TransportWebUSB.create(120_000, 120_000);
      // Setup signer
      const offlineSigner = new LedgerSigner(ledgerTransport, {
        hdPaths: [makeCosmoshubPath(0), makeCosmoshubPath(1), makeCosmoshubPath(10)],
        testModeAllowed: true,
        //@ts-ignore
        prefix: (environment.env == 'prod') ? environment.cosmos_config['COSMOSHUB-4'].prefix : environment.cosmos_config['MALAGA-420'].prefix,
      });
      const result = (await offlineSigner.getAccounts())[0]
      let pubkey = toBase64(result.pubkey);
      let address = result.address;
      // console.log('pubkey',pubkey);
      // console.log('address',address);
      // console.log('pubkey11',toBase64((await offlineSigner.getAccounts())[0].pubkey));
      if (result) {
        this.linking_cosmos = false;
        this.linked_coin.push("cosmos");
        let wallet: PubKeys = { xpub: "", path: "", id: "", type: "", name: "", config: "" };
        wallet.xpub = address;
        //@ts-ignore
        wallet.path = (environment.env == 'prod') ? environment.cosmos_config['COSMOSHUB-4'].path : environment.cosmos_config['MALAGA-420'].path || "m/" + "44'/118'/0'/0/0";
        wallet.id = address;
        //@ts-ignore
        wallet.coin = (environment.env == 'prod') ? environment.cosmos_config['COSMOSHUB-4'].nativecoin : environment.cosmos_config['MALAGA-420'].nativecoin;
        wallet.type = "address"
        wallet.provider = "ledger"
        console.log('cosmos data', wallet)

        await this.httpService.saveKey(wallet, 'ledger').toPromise()
        wallet.xpub = pubkey;
        wallet.id = pubkey;
        wallet.type = "pubkey"
        wallet.coin = "COSMOS"
        await this.httpService.saveKey(wallet, 'ledger').toPromise()
      } else {
        //@ts-ignore
        this.coin_name = (environment.env == 'prod') ? environment.cosmos_config['COSMOSHUB-4'].nativecoin : environment.cosmos_config['MALAGA-420'].nativecoin;
        this.error_message(this.coin_name);
      }

      try {
        this.segment.track("link-device-cosmos-success", this.authService.getUser)
          .then(() => console.log("Link Device Cosmos Success"));
      } catch (err) {
        this.logger.error(err, err);
        console.log(err);
      }
    }
  }
  catch (err) {
    ledgerLinkErrorHandler.call(this, err, 'bch');
  }
  let currentapp = await getCurrentApp(transport);
  if (currentapp.name != "BOLOS") {
    //Exit app
    await transport.send(0xb0, 0xa7, 0x00, 0x00);
    await this.delay(2000);
  }
  this.ledger_linking_bch = false;
  this.linking_bch = false;
  this.linking_Ledger_Error = false;

  //this needs to be changed for the last coin to be linked

};//end