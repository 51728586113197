<!-- <button id="CB_Whitelist_Policy" href="javascript:void(0);" *ngIf="component_for == 'policy'"
    [ngClass]="{'disabled' : _wallets?.length == 0}" [disabled]="_wallets?.length == 0"
    class="btn btn-primary-light btn-md" title="Add Policy" (click)="addPolicy(addPolicyModal)">
    <i class="icon-add-alt"></i>
    <span class="d-inline ml-1">Add</span>
</button> -->

<button id="CB_Whitelist_Policy" 
    *ngIf="component_for=='add_team'"
    class="btn btn-primary-light btn-md" title="Add Teams" (click)="addTeam(addTeamModal)">
    <i class="icon-add-alt"></i>
    <span class="d-inline ml-1">Add</span>
</button>

<button id="CB_Whitelist_Policy" 
    *ngIf="component_for=='add_team_add'"
    class="btn btn-primary btn-md" title="Add Teams" (click)="addTeam(addTeamModal)">
    <i class="icon-add-alt"></i>
    <span class="d-inline ml-1">Add Team</span>
</button>

<button id="CB_Wallet_Team" 
    *ngIf="component_for=='new_wallet_team'"
    class="btn btn-link primary-color text-underline p-0 pb-0 pr-0 pt-0" title="Add Teams" (click)="addTeam(addTeamModal)">
    <!-- <i class="icon-add-alt"></i> -->
    <span class="d-inline text-primary  " >Add Team</span>
</button>

<ng-template #addTeamModal let-modal >
    <div class="modal-custom" *ngIf="status != 'success'">
        <div class="modal-header">
            <h2 class="modal-title">
                <!-- <i class="icon-shield mr-2"></i> -->
                
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-people mr-2" viewBox="0 0 16 16">
                    <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z"/>
                  </svg>
                <ng-container >Add Team</ng-container>
               </h2>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <div class="mb-4">
                        <div class="mb-4">
                            <span class="pb-2 text-muted">Team name</span>
                            <input class="form-control search-custom mt-1" style="border-radius: 6px;" [(ngModel)]="teamName" placeholder="Enter team name" type="text" pattern="[a-zA-Z0-9 ]*">
                        </div>

                        <div class="mt-2 pb-3">
                            <span class="font-weight-bold text-muted">Signer Type</span>
                            <div class="mb-4 mt-1 " style="margin-left: auto !important;" ngbDropdown #myDropType="ngbDropdown" >
                                <div class="d-flex test-2" >
                                    <input disabled autocomplete="off" class="form-control search-custom  full-dropdown " id="dropdownManual"
                                        ngbDropdownAnchor (focus)="myDropType.open(); focusToType=true" (blur)="focusToType=false"
                                        type="text" value="Mobile (MPC)" (keydown)="$event.preventDefault()"/>
        
                                    <!-- <svg *ngIf="!focusToType" (click)="myDropType.open()" xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" style="color: gray;" class="bi bi-caret-down-fill iconCenter pr-2" viewBox="0 0 16 16">
                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                    </svg>
                                    <svg *ngIf="focusToType" (click)="myDropType.close()"  xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" style="color: gray;" class="bi bi-caret-up-fill iconCenter pr-2" viewBox="0 0 16 16">
                                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                                    </svg> -->
                                </div>
                                <!-- <ul ngbDropdownMenu class="whitelistscroll py-0" style="min-width: 28.4rem;" >
                                    <div class="d-flex " *ngFor="let data of walletType" style="border-bottom: 1px solid rgba(0, 0, 0, 0.15)">
                                        <button class="dropdown-content card brd_Box p-0 m-0" style="width: 100%; border: none !important;"
                                            (click)="onDropDownBtnClick(data)">
                                            <li ngbDropdownItem class="p-3 text-wrap">
                                                <div class="d-flex  flex-column" >
                                                    <div class="text-left text-margin">
                                                            {{data.name}}
                                                    </div>
                                                    <div class="ml-auto"></div>
                                                </div>
                                            </li>
                                        </button>
                                    </div>
                                </ul> -->
                            </div>
                        </div>

                        <div class="mb-4 p-2 border border-rounded" >
                        <!-- -----------------------initiator ------------------------------- -->

                            <div class="d-flex p-2"> 
                                <div class="d-flex">
                                    <span class="font-size-14  mr-2">Initiators</span>
                                    <div class="d-flex" [ngbTooltip]="'Initiators must first initiate the transaction request'" placement="right" style="align-self: center;"> 
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-circle text-center" style="align-self: center;" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                        </svg>
                                    </div>
                                </div>
                                <div class="d-inline float-right" style="margin-left: auto;">
                                    <span class="text-muted" href="#">{{ minInt.length>0 ? minInt.length + '  Selected': ''}}</span>
                                </div>
                            </div>
                            
                            
                            <div class="pl-2 pr-2 mb-3">
                                <ng-multiselect-dropdown 
                                [settings]="dropdownSettingsInitiator" 
                                name="Initiator" 
                                placeholder="Select Initiators" 
                                (onSelect)="onMemberSelect($event,'initiator')" 
                                (onDeSelect)="onMemberDeSelect($event,'initiator')" 
                                [data]="_membersInitiator">
                                    <ng-template #optionsTemplate let-item let-option="option" let-id="id" 
                                        let-isSelected="isSelected" class="d-flex ms-2">
                                        <div class="d-flex" [style]="isSelected?'display:none !important':''"
                                            >
                                            <div class="d-flex align-items-center box_Circle_Qr p-2">
                                                <img class="img-fluid for-light rounded-circle user-image "
                                                    [src]="getMembers[id].phptoURL" alt="">
                                            </div>
                                            <div class="details d-flex flex-column ">
                                                <div class="text-left p-0 mb-auto mt-auto">
                                                    <span #name class="m-0 truncate_Name">{{ getMembers[id].displayName }}</span>
                                                </div>
                                                
                                            </div>
                                        </div>
                                       
                                    </ng-template>
                                    <ng-template #optionSelectedTemplate let-option="option" let-id="id" >
                                        <div class="d-flex">
                                            <div class="d-flex align-items-center box_Circle_Qr">
                                                <img class="img-fluid for-light rounded-circle-white-chip user-image mr-2"
                                                    [src]="getMembers[id].phptoURL" alt="">
                                            </div>
                                            <div class="details d-flex align-items-center">
                                                <div class="col text-left p-0">
                                                    <span #name class="m-0 truncate_Name ">{{ option }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ng-multiselect-dropdown>
                            </div>
                            <div class="d-flex pl-2">
                                <span class="minimun-text text-muted mt-2">Minimum initiators required</span>
                                <!-- <input type="text" > -->
                                <div class="mb-4 mx-1" style="margin-left: auto !important; padding-right: 6px !important;" ngbDropdown #myDropInitiator="ngbDropdown" >
                                    <div class="d-flex test-2" [ngClass]="{'disabled': minInt?.length>0?false:true}" [disabled]="minInt?.length>0?false:true">
                                        <input autocomplete="off" class="form-control search-custom  test " id="dropdownManual"
                                            ngbDropdownAnchor (focus)="myDropInitiator.open(); focusTotInit=true" (blur)="focusTotInit=false"
                                            type="text" [value]="minimum_initiator_selected" [(ngModel)]="minimum_initiator_selected" (keydown)="$event.preventDefault()"/>
        
                                        <svg *ngIf="!focusTotInit" (click)="myDropInitiator.open()" xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" style="color: gray;" class="bi bi-caret-down-fill iconCenter pr-2" viewBox="0 0 16 16">
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                          </svg>
                                          <svg *ngIf="focusTotInit" (click)="myDropInitiator.close()"  xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" style="color: gray;" class="bi bi-caret-up-fill iconCenter pr-2" viewBox="0 0 16 16">
                                            <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                                          </svg>
                                    </div>
                                    <ul ngbDropdownMenu class="whitelistscroll py-0" >
                                        <div class="d-flex " *ngFor="let data of minimumInitiator" style="border-bottom: 1px solid rgba(0, 0, 0, 0.15)">
                                            <button class="dropdown-content card brd_Box p-0 m-0" style="width: 100%;"
                                                (click)="this.minimum_initiator_selected = data">
                                                <li ngbDropdownItem class="pl-3 pr-2 pt-2 pb-2 text-wrap">
                                                    <div class="d-flex align-items-center " >
                                                        <div class="text-left text-margin">
                                                            <div class="text-dark ">
                                                                {{data}}
                                                            </div>
                                                        </div>
                                                        <div class="ml-auto"></div>
                                                    </div>
                                                </li>
                                            </button>
                                        </div>
                                    </ul>
                                </div>
                            </div>


                        <!-- -----------------------Signer ------------------------------- -->
                            <ng-container *ngIf="!(orgProfile?.orgType == orgType.sub && orgProfile?.custodyType == custodyType.CUSTODY)">
                                <div class="d-flex p-2"> 
                                    <div class="d-flex">
                                        <span class="font-size-14 mr-2">Signers</span>
                                        <div class="d-flex" [ngbTooltip]="'Signers can only sign the transaction after it is approved by initiators'" placement="right" style="align-self: center;"> 
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-circle text-center" style="align-self: center;" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="d-inline float-right" style="margin-left: auto;">
                                        <span class="text-muted" href="#">{{ minSig.length>0 ? minSig.length + '  Selected': ''}}</span>
                                    </div>
                                </div>
                                
                                <div class="pl-2 pr-2 mb-3">
                                    <ng-multiselect-dropdown [settings]="dropdownSettingsSigner" name="Signers" [disabled]="!_membersSigner?.length"
                                        placeholder="Select Signers" [ngbTooltip]="!_membersSigner?.length && 'Signers must have their mobile signer linked'"
                                        (onSelect)="onMemberSelect($event,'signer')" (onDeSelect)="onMemberDeSelect($event,'signer')" [data]="_membersSigner">
                                        <ng-template #optionsTemplate let-item let-option="option" let-id="id"
                                            let-isSelected="isSelected" class="d-flex ms-2">
                                            
                                            <div  class="d-flex" [ngClass]="isSelected?'selected':''" [style]="isSelected?'display:none !important':''">
                                                <div class="d-flex align-items-center box_Circle_Qr p-2">
                                                    <img class="img-fluid for-light rounded-circle user-image "
                                                        [src]="getMembers[id].phptoURL" alt="">
                                                </div>
                                                <div class="details d-flex flex-column ">
                                                    <div class="text-left p-0 mb-auto mt-auto">
                                                        <span #name class="m-0 truncate_Name">{{ getMembers[id].displayName }}</span>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template #optionSelectedTemplate let-option="option" let-id="id">
                                            <div class="d-flex">
                                                <div class="d-flex align-items-center box_Circle_Qr">
                                                    <img class="img-fluid for-light rounded-circle-white-chip user-image mr-2"
                                                        [src]="getMembers[id].phptoURL" alt="">
                                                </div>
                                                <div class="details d-flex align-items-center">
                                                    <div class="col text-left p-0">
                                                        <span #name class="m-0 truncate_Name ">{{ option }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ng-multiselect-dropdown>
    
                                </div>
                                <div class="d-flex pl-2">
                                    <span class="minimun-text text-muted mt-2">Minimum signers required</span>
                                    <!-- <input type="text" > -->
                                    <div class="mb-4 mx-1" style="margin-left: auto !important; padding-right: 4px !important;" ngbDropdown #myDropSigner="ngbDropdown" >
                                        <div class="d-flex test-2" [ngClass]="{'disabled': minSig?.length>0?false:true}" [disabled]="minSig?.length>0?false:true">
                                            <input autocomplete="off" class="form-control search-custom  test-3 " id="dropdownManual"
                                                ngbDropdownAnchor 
                                                disabled
                                                type="text" [value]="minimum_signer_selected" [(ngModel)]="minimum_signer_selected" (keydown)="$event.preventDefault()"/>
            
                                            <!-- <svg *ngIf="!focusTotSign" (click)="myDropSigner.open()" xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" style="color: gray;" class="bi bi-caret-down-fill iconCenter pr-2" viewBox="0 0 16 16">
                                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                              </svg>
                                              <svg *ngIf="focusTotSign" (click)="myDropSigner.close()"  xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-caret-up-fill iconCenter pr-2" viewBox="0 0 16 16">
                                                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                                              </svg> -->
                                        </div>
                                        <ul ngbDropdownMenu class="whitelistscroll py-0" style="left: -60px !important;">
                                            <div class="d-flex " *ngFor="let data of minimumSigner" style="border-bottom: 1px solid rgba(0, 0, 0, 0.15)">
                                                <button class="dropdown-content card brd_Box p-0 m-0" style="width: 100%;"
                                                    (click)="this.minimum_signer_selected = data">
                                                    <li ngbDropdownItem class="pl-3 pr-2 pt-2 pb-2 text-wrap">
                                                        <div class="d-flex align-items-center " >
                                                            <div class="text-left text-margin">
                                                                <div class="text-dark ">
                                                                    {{data}}
                                                                </div>
                                                            </div>
                                                            <div class="ml-auto"></div>
                                                        </div>
                                                    </li>
                                                </button>
                                            </div>
                                        </ul>
                                        
                                    </div>
                                </div>
                            </ng-container>
                            
                        </div>
                        
                      
                    </div>
                    
                </div>
            </div>
        </div>
        <ng-container *ngIf="orgProfile?.orgType == orgType.sub && orgProfile?.custodyType == custodyType.CUSTODY;else secondBtn">
            <div class="modal-footer border-0">
                <button (click)="!isLoading&&addTeamBtn()" [disabled]="!this.teamName || !this.minInt.length || isLoading"
                    class="btn btn-outline-primary w-100">Add Team</button>
            </div>
        </ng-container>
        <ng-template #secondBtn>
            <div class="modal-footer border-0">
                <button (click)="!isLoading&&addTeamBtn()" [disabled]="!this.teamName || !this.minInt.length || !this.minSig.length || isLoading"
                    class="btn btn-outline-primary w-100">Add Team</button>
            </div>
        </ng-template>
    </div>
 
    <div class="modal-custom" *ngIf="status == 'success'">
        <div class="modal-header border-0">
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');"> <i
                    class="icon-close"></i> </button>
        </div>
        <div class="modal-body d-flex align-items-center" style="background-color: #fff;">
            <div class="row">
                <div class="col-12">
                    <div class="mb-3 position-relative">
                        <div class="background_success text-center"> <img class="w-50"
                                src="../../../assets/images/check-outline-success.gif"> </div>
                    </div>
                    <div class="mb-5 text-center">
                        <div class="h5">Success</div>
                        <div class="text-muted">Awesome! You have successfully added
                            <br> the team 🎉
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0">
            <button type="submit" class="btn btn-outline-primary w-100"
                (click)="modal.dismiss('Cross click');"> Done
            </button>
        </div>
    </div>
</ng-template>
