import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as localforage from 'localforage'
import { windowToggle } from 'rxjs-compat/operator/windowToggle';

@Injectable({
    providedIn: 'root'
})
export class LocalForageService {

    private data = new BehaviorSubject({});
    store = this.data.asObservable();

    async allData() {
        let wallets = {};
        localforage.iterate(function(value, key) {
            wallets[key] = value;
        }).then(() => {
            this.data.next(wallets);
        });
    }

    constructor() { }
}
