import { Component, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { AuthServiceJWT } from './shared/services/auth.service';
import { getParameterByName } from 'src/app/shared/helpers/general';
import { LoggerService } from './shared/services/logger.service';
import OverrideConsole from './shared/services/console.override';
import { environment } from '../environments/environment';

(window as any).global = window;
// @ts-ignore
window.Buffer = window.Buffer || require("buffer").Buffer;

//@ts-ignore
window.walletconnectweb3wallet = window["@walletconnect/web3wallet"];
//@ts-ignore
window.walletconnectCore = window["@walletconnect/core"];
//@ts-ignore
window.walletconnectUtils = window["@walletconnect/utils"];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent {

  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(500),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService, private authService: AuthServiceJWT, logger: LoggerService) {
    if (getParameterByName('message') == 'Your email was verified. You can continue using the application.') {
      this.authService.SignOut();
    }
    if (environment.env !== "dev") {
      OverrideConsole(logger);
    }
  }
}
