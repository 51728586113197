
export interface User {
  displayName: string;
  photoURL?: string;
  email?: string
  phoneNumber?: string
  status?: number
}
export interface IAuthUserType extends User {
  email_verified?: boolean
}
export interface generate_api {
  client_id: string;
  generated_on?: Date;
  client_secret?: string
}


export enum UserTypeEnum {
  NORMAL = 1,
  ADMIN = 2,
}

