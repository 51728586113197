import { AfterViewInit, Component, OnInit } from '@angular/core';
import { LayoutService } from '../../../services/layout.service';
import { NavService } from '../../../services/nav.service';
import { fadeInAnimation } from '../../../data/router-animation/router-animation';
import { DataService } from 'src/app/shared/services/data.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { AuthServiceJWT } from 'src/app/shared/services/auth.service';
@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  animations: [fadeInAnimation]
})
export class ContentComponent implements OnInit, AfterViewInit {
  isLoading = true;
  constructor(public navServices: NavService,
    public layout: LayoutService, private data: DataService, private httpService: HttpService, private authService: AuthServiceJWT) {
  }
  ngAfterViewInit() {
  }
  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }
  ngOnInit() {
    this.httpService.getprofile().subscribe(async (userDetailRequests) => {
      this.data.changeUserProfileRequest(userDetailRequests);
      this.isLoading = false;
    });
  }
}
