<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
  <div class="header-wrapper row m-0">
    <div>
      <a href="javascript:void(0);" routerLink="/">
        <img src="../../../../assets/images/logo/logo.png" class="img-fluid" />
      </a>
    </div>
    <div class="nav-right pull-right right-header ml-auto p-0">
      <ul class="nav-menus" *ngIf="loading">
        <li class="p-0">
          <ngx-skeleton-loader></ngx-skeleton-loader>
        </li>
        <!-- <li class="maximize">
          <a class="text-dark" href="javascript:void(0)" (click)="toggleFullScreen()">
            <i class="icon-fullscreen" style="vertical-align: middle;"></i>
          </a>
        </li> -->
        <li class="profile-nav onhover-dropdown p-0 ml-3">
          <ngx-skeleton-loader></ngx-skeleton-loader>
        </li>
      </ul>
      <ul class="nav-menus" *ngIf="!loading">
        <li class="p-0" *ngIf="orgProfile.orgType !== orgType.sub">
          <app-wallet-connect></app-wallet-connect>
        </li>
        <!-- <li class="maximize">
          <a class="text-dark" href="javascript:void(0)" (click)="toggleFullScreen()">
            <i class="icon-fullscreen" style="vertical-align: middle;"></i>
          </a>
        </li> -->
        <li class="profile-nav onhover-dropdown p-0 ml-3">
          <app-my-account [link_component_for]="'menu'"></app-my-account>
        </li>
      </ul>
     
    </div>
  </div>
</div>
<!-- <div class="header-wrapper row m-0 mb-20">
  <div class="col-sm-12 col-xl-12 xl-100">
    <div class="row2">
    </div>
  </div>
</div> -->

<app-trezor-webusb></app-trezor-webusb>