import { Routes } from '@angular/router';
import { OrgAuthGuard } from 'src/app/org-auth-guard.guard';


export const content: Routes = [
  {
    path: '',
    loadChildren: () => import('../../components/wallet/wallet.module').then(m => m.SampleModule),
    canActivate: [OrgAuthGuard]
  },
  {
    path: 'footer',
    loadChildren: () => import('../../shared/components/footer/footer.component').then(m => m.FooterComponent)
  }
];
