import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { TransactionRequest } from '../entities/TransactionRequest';
import { MpcTxData, MpcTxRequest } from '../entities/mpcTxRequest';
import { Wallet, getStakingWalletList } from '../entities/wallet'
import { Transaction } from '../entities/Transaction';
import { generate_api } from '../entities/User' ;
import { pendingPolicyRequest, pendingUserRequest, getUserProfile } from '../entities/Policy'
import { StakingV2 } from '../entities/StakingV2'
import { WalletRequest } from '../entities/walletRequest';

@Injectable()
export class DataService {

  private pageSource = new BehaviorSubject('settings');
  currentPage = this.pageSource.asObservable();

  changePage(page: string) {
    this.pageSource.next(page)
  }

  private messageSource = new BehaviorSubject('default');
  currentMessage = this.messageSource.asObservable();
  allScheduleTransactionRequests: BehaviorSubject<TransactionRequest[]> = new BehaviorSubject<TransactionRequest[]>(null)
  allSchediletxs = this.allScheduleTransactionRequests.asObservable();
  txRequest: BehaviorSubject<TransactionRequest> = new BehaviorSubject<TransactionRequest>(null)
  txMpcRequest: BehaviorSubject<MpcTxData> = new BehaviorSubject<MpcTxData>(null)
  txMessageSignRequest: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  txRequest_mobile: BehaviorSubject<TransactionRequest> = new BehaviorSubject<TransactionRequest>(null)
  pendingMessageSigningRequest: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  pendingTxRequest: BehaviorSubject<TransactionRequest[]> = new BehaviorSubject<TransactionRequest[]>(null)
  pendingMpcRequest: BehaviorSubject<MpcTxData[]> = new BehaviorSubject<MpcTxData[]>(null)
  pendingTravelRuleTxRequest: BehaviorSubject<TransactionRequest[]> = new BehaviorSubject<TransactionRequest[]>(null)
  walletRequest: BehaviorSubject<WalletRequest[]> = new BehaviorSubject<WalletRequest[]>(null)
  wallets: BehaviorSubject<Wallet[]> = new BehaviorSubject<Wallet[]>(null)
  triggerRefresh:BehaviorSubject<any>=new BehaviorSubject<any>(null);
  scheduledTxRequest: BehaviorSubject<TransactionRequest[]> = new BehaviorSubject<TransactionRequest[]>(null)
  txs: BehaviorSubject<Transaction[]> = new BehaviorSubject<Transaction[]>(null)
  pendingPolicyRequest: BehaviorSubject<pendingPolicyRequest[]> = new BehaviorSubject<pendingPolicyRequest[]>(null)
  pendingUserRequest: BehaviorSubject<pendingUserRequest[]> = new BehaviorSubject<pendingUserRequest[]>(null)
  getUserProfile: BehaviorSubject<getUserProfile> = new BehaviorSubject<getUserProfile>(null);
  getStakingWalletList: BehaviorSubject<getStakingWalletList[]> = new BehaviorSubject<getStakingWalletList[]>(null)
  getStakingV2WalletList: BehaviorSubject<StakingV2> = new BehaviorSubject<StakingV2>(null)
  generate_api: BehaviorSubject<generate_api[]> = new BehaviorSubject<generate_api[]>(null)
  constructor() { }

  changeMessage(message: string) {
    this.messageSource.next(message)
  }

  newTxRequest(txRequestNew: TransactionRequest) {
    this.txRequest.next(txRequestNew);
  }

  newMpcTxRequest(txRequestNew: MpcTxData) {
    this.txMpcRequest.next(txRequestNew);
  }

  newMessageSignRequest(txRequestNew: MpcTxData) {
    this.txMessageSignRequest.next(txRequestNew);
  }

  newTxRequest_mobile(txRequestNew: TransactionRequest) {
    this.txRequest_mobile.next(txRequestNew);
  }

  changePendingTxRequest(txRequests: TransactionRequest[]) {
    if (txRequests && this.pendingTxRequest.value == txRequests) {
      return;
    }
    this.pendingTxRequest.next(txRequests)
  }

  changePendingTravelRuleTxRequest(txRequests: TransactionRequest[]) {
    if (txRequests && this.pendingTravelRuleTxRequest.value == txRequests) {
      return;
    }
    this.pendingTravelRuleTxRequest.next(txRequests)
  }

  changePendingMessageSigningRequest(txRequests: any) {
    if (txRequests && this.pendingMessageSigningRequest.value == txRequests) {
      return;
    }
    this.pendingMessageSigningRequest.next(txRequests)
  }

  changeMpcPendingTxRequest(txRequests: MpcTxData[]) {
    if (txRequests && this.pendingMpcRequest.value == txRequests) {
      return;
    }
    this.pendingMpcRequest.next(txRequests)
  }

  changeWalletRequest(req: WalletRequest[]) {
    if (req && this.walletRequest.value == req) {
      return;
    }
    this.walletRequest.next(req)
  }

  changePendingPolicyRequest(txRequests: pendingPolicyRequest[]) {
    if (txRequests && this.pendingPolicyRequest.value == txRequests) {
      return;
    }
    this.pendingPolicyRequest.next(txRequests)
  }

  changependingUserRequest(pendingRequests: pendingUserRequest[]) {
    this.pendingUserRequest.next(pendingRequests)
  }

  changeUserProfileRequest(userProfile: getUserProfile) {
    this.getUserProfile.next(userProfile)
  }

  changeStakingWalletRequest(walletRequests: getStakingWalletList[]) {
    this.getStakingWalletList.next(walletRequests)
  }

  changeStakingV2WalletRequest(walletRequests: StakingV2) {
    this.getStakingV2WalletList.next(walletRequests)
  }

  changeScheduledTxRequest(txRequests: TransactionRequest[]) {
    this.scheduledTxRequest.next(txRequests)
  }

  changeTxs(txs: Transaction[]) {
    this.txs.next(txs)
  }

  changeWallets(wallets: Wallet[]) {
    if (wallets && this.wallets.value == wallets) {
      return;
    }
    this.wallets.next(wallets);
  }

  nextAllScheduleTransactionRequests(allScheduleTransactionRequests: TransactionRequest[]) {
    this.allScheduleTransactionRequests.next(allScheduleTransactionRequests);
  }

  get_api_details(generated_api: generate_api[])
  {
    this.generate_api.next(generated_api);
  }


}