import { Component, OnInit } from "@angular/core";
import { HttpService } from "../../shared/services/http.service";
import { AuthServiceJWT } from "../../shared/services/auth.service";
import { AuthService } from "@auth0/auth0-angular";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DataService } from "src/app/shared/services/data.service";
import { SegmentService } from 'ngx-segment-analytics';
@Component({
  selector: "app-verify-email",
  templateUrl: "./verify-email.component.html",
  styleUrls: ["./verify-email.component.scss"],
})
export class VerifyEmailComponent implements OnInit {
  success: boolean = false;
  resend = true;
  timeLeft = 10;
  authDetails = null;
  userDetails: any;
  loading: boolean = false;
  ngIf="!authDetails.emailVerified"

  constructor(
    private segment: SegmentService,
    public router: Router,
    private httpService: HttpService,
    public authService: AuthServiceJWT,
    public afAuth: AuthService,
    private data: DataService
  ) {
    this.afAuth.user$.subscribe(async (user) => {
      if (user) {
        const token = await this.afAuth.getIdTokenClaims().toPromise();
        this.authDetails = {
          email: user.email,
          displayName: user.name,
          uid: user.email,
          photoURL: user.picture || "assets/dashboeard/boy-2.png",
          emailVerified: user.email_verified,
          sub: user.sub,
          iss: token.iss,
        };
        if (this.authDetails.emailVerified) {
          this.segment.track("email-verification-success");
          this.router.navigate(['/']);
        }
      }
    });
  }

  ngOnInit(): void {
    console.log('dc',this.authDetails);
    
  }

  async resendEmail() {
    this.resend = false;
    this.timer();
    Swal.fire({
      text: "Email verification sent. Kindly check your email address.",
      icon: "success",
      timer: 2500,
      showConfirmButton: false,
    });
    await this.httpService
      .resendEmailVerification({
        email: this.authDetails.email,
        userid: this.authDetails.sub,
        iss: this.authDetails.iss,
      })
      .toPromise();
  }

  timer() {
    this.timeLeft = 10;
    let interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 0;
        clearInterval(interval);
        this.resend = true;
      }
    }, 1000);
  }

  logout() {
    this.authService.SignOut();
  }
}
