import Swal from 'sweetalert2';

export function ledgerLinkErrorHandler(err: Error, coin: string) {
  this.logger.error(err);
  try {
    this.segment.track(`link-device-${coin}-fail`, this.authService.getUser)
      .then(() => console.log(`Link Device ${coin} Fail`));
  } catch (err) {
    this.logger.error(err, err);
  }
  if (err.name == "TransportStatusError") {
    Swal.fire({
      title: 'Fail!',
      text: 'Error while exporting Pubkeys!!',
    })
  }
  else {
    //alert(err.message);
  }
}