import { Output, TransactionRequest, PreBuildTx, SignedTransaction } from '../../../entities';
import TransportWebHID from "@ledgerhq/hw-transport-webhid";
import Swal from 'sweetalert2';
import Trx from "@ledgerhq/hw-app-trx";
import { LedgerService } from '../../ledger/ledger';
import { Guid } from "guid-typescript";


function errorHandler(err: any) {
    if (err.message == "Ledger device: UNKNOWN_ERROR (0x6a8d)") {
        Swal.fire({
            // position: 'top-end',
            icon: 'error',
            title: 'Failed to sign transaction!',
            text: "Allow unverified contracts from Ledger app setting.",
            showConfirmButton: false,
            // timer: 1500
        })
        try {
            this.segment.track("send-asset-transaction-sign-failed", this.authService.getUser)
                .then(() => console.log("Send asset transaction sign failed"));
        } catch (err) {
            this.logger.error(err, err);
        }
    }
    else if (err.name == "TransportOpenUserCancelled") {
        Swal.fire({
            // position: 'top-end',
            icon: 'error',
            title: 'Failed to sign transaction!',
            text: "Device connection error",
            showConfirmButton: false,
            // timer: 1500
        })
        try {
            this.segment.track("send-asset-transaction-sign-failed", this.authService.getUser)
                .then(() => console.log("Send asset transaction sign failed"));
        } catch (err) {
            this.logger.error(err, err);
        }
    } else {
        if (err.response?.data?.message) {
            Swal.fire({
                icon: 'error',
                title: 'Failed to sign transaction!',
                text: err.response.data.message,
                showConfirmButton: false,
            })
        } else {
            Swal.fire({
                // position: 'top-end',
                icon: 'error',
                title: 'Failed to sign transaction!',
                text: err.message,
                showConfirmButton: false,
                // timer: 1500
            })
        }
        try {
            this.segment.track("send-asset-transaction-sign-failed", this.authService.getUser)
                .then(() => console.log("Send asset transaction sign failed"));
        } catch (err) {
            this.logger.error(err, err);
        }
    }
}

export async function signTronTransaction(output: Output, req: TransactionRequest, type: string) {
    try {
        let transport = await TransportWebHID.create();
        let txParam: any = {}
        var txJSON;
        var txid;
        let CoinName = "Tron";
        if (req && 'raw' in req) {
            let objwalletkey = this.wallet.walletKeys.filter(item => item.ismine === true);
            txid = req.id;
            txParam = JSON.parse(req.raw);
            txJSON = JSON.stringify(txParam)
            console.log(JSON.parse(txParam.halfSigned.payload));
            var JSONPayload = JSON.parse(txParam.halfSigned.payload);
            var jsnpayloaddata = typeof JSONPayload.data === 'object' && JSONPayload.data !== null ? JSONPayload.data : JSON.parse(JSONPayload.data);
            var raw_tx = jsnpayloaddata.raw_data_hex;
            const trx = new Trx(transport);
            const signature = await trx.signTransaction("44'/195'/0'/0/0", raw_tx, []);
            await transport.close();
            var data =
            {
                "sig": signature,
                "pubkey": objwalletkey[0].xpub
            }
            var respApprove = await this.httpService.ApproveTx(JSON.stringify(data), this.wallet.id, txid);
            console.log(respApprove)
            if (respApprove.status !== 200) {
                return [{ "error": respApprove }]
            }
            return "success";
        }
        else {
            var respData: PreBuildTx = await this.httpService.getPrebuildTx({
                reqobj:
                {
                    nonce: 0,
                    output: output,
                    wallet: this.wallet,
                    type: type.toLowerCase(),
                }
            })
            var raw_tx = respData.txJson.raw_data_hex;
            let currentapp = await LedgerService.getCurrentApp(transport);
            if (currentapp.name != CoinName) {
                return [{ "error": "please Open Tron App on Ledger and Try Again" }]
            }
            //await LedgerService.openApp(CoinName,transport);

            const trx = new Trx(transport);
            const signature = await trx.signTransaction("44'/195'/0'/0/0", raw_tx, []);
            await transport.close();
            let sequenceId = Guid.create().toString();
            if (req) {
                if ('sequenceId' in req) {
                  sequenceId = req['sequenceId'] as string;
                }
            }
            let strinifyraw_data = JSON.stringify(respData.txJson.raw_data);
            let finalTx =
            {
                visible: respData.txJson.visible,
                txID: respData.txJson.txID,
                raw_data: JSON.parse(strinifyraw_data),
                raw_data_hex: respData.txJson.raw_data_hex,
                signature: [signature]
            }
            let tx = {
                destinationAddress: respData.txOutputs.destinationAddress,
                data: (finalTx),
                value: respData?.txOutputs.destinationAmount.toString(),
                amount: respData?.txOutputs.destinationAmount.toString(),
                coinSymbol: respData?.txOutputs.coinSymbol.toString()
            }
            const signTransactionResponse: SignedTransaction = {
                txHex: JSON.stringify(signature),
                halfSigned: {
                    payload: JSON.stringify(tx),
                    txBase64: "",
                    txHex: respData.txJson.raw_data_hex,
                    sequenceId: sequenceId
                },
            };
            var sendresult = await this.httpService.SendTransaction(this.wallet.id, signTransactionResponse);
            sendresult = (sendresult)
            console.log(sendresult);
            if (sendresult.status !== 200) {
                return [{ "error": sendresult }]
            }
            return "success";
        }
    }
    catch (err) {
        errorHandler.call(this, err);
    }
}