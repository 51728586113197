import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from './http.service';
import { signMessage } from "../helpers/trezor/messageSigner";
import { AuthServiceJWT } from './auth.service';


interface ApiResponse {
    success: boolean,
    message: string
}
@Injectable({
    providedIn: 'root'
})
export class AppService {
    constructor(private HttpService: HttpService,private auth: AuthServiceJWT) { }

    private View = new BehaviorSubject(true);
    currentView = this.View.asObservable();
    private tokenView = new BehaviorSubject(true);
    currentTokenView = this.tokenView.asObservable();
    private appsWallet = new BehaviorSubject("");
    activeAppsWallet = this.appsWallet.asObservable();

    changeView(View: any) {
        this.View.next(View);
    }

    changeTokenView(View: any) {
        this.tokenView.next(View);
    }

    onAppsDetails(View: any) {
        this.appsWallet.next(View)
    }

    async enableWalletSign(appDetail, id, comment, deviceName,walletName): Promise<ApiResponse> {
        const today = (new Date).toISOString().slice(0, 10);
        let message = "Enable appid " + appDetail.id + " name " + appDetail.name + " in " +walletName +" at " + today;

        try {
            const body = {
                pubKey: "",
                message: message,
                signature: "0x" + "",
                label: comment,
                policyType: "enable_app"
            }
            return new Promise((resolve, reject) => {
                this.auth.writeAccess("policies:add").subscribe((accessToken) => {
                  this.HttpService.enableAppWallet(id, body,accessToken)
                    .toPromise()
                    .then((result) => {
                      resolve(result);
                    })
                    .catch((error) => {
                      reject(error);
                    });
                });
            });
        } catch (e) {
            console.error(e);
            return Promise.reject({ success: false, message: "message signing failed" });
        }

    }

    async signApproveAsset(appDetail, assetDetail) {

        const body = {
            identifier: assetDetail.identifier
        }
        const response = this.HttpService.approveAppToken(appDetail.id, assetDetail.walletid, body).toPromise().catch((err) => { return err });
        return response
    }

    async signDisableAsset(appDetail, assetDetail, comment) {
        const today = (new Date).toISOString().slice(0, 10);
        let message = "Disable appid " + appDetail.id + " from wallet " + assetDetail.name + " at " + today;

        try {
            const signResponse = await this.signEthereumMessage("trezor", message)
            if (signResponse) {

                const body = {
                    pubKey: signResponse.pubKey,
                    message: signResponse.message,
                    signature: "0x" + signResponse.signature,
                    label: comment ? comment : "",
                    policyId: assetDetail.id
                }
                return this.HttpService.disableAppAsset(assetDetail.walletid, body).toPromise();
            }
        } catch (e) {
            console.error(e);
            return Promise.reject({ success: false, message: "message signing failed" });
        }
    }

    async signEthereumMessage(device, message) {
        console.info("device name -- message -->", device, message)
        return await signMessage(device, message);
    }

}