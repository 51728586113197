export interface TransactionRequest {
  id?: number;
  raw: any;
  walletid?: number | string;
  status?: any;
  txDisplayTx?: string;
  destinationAddress: string;
  amount: number;
  usdamount?: number;
  asset: string;
  chain: string;
  identifier?: string;
  pubkey?: string;
  signs?: signs[]
  timestamp?: any;
  metadata?:TransactionRequestMetadata;
  _metaData?:any;
  comment?:string;
  userid?:number;
  type: number;
  walletinfor?:any;
  addressLabel?:string;
  scheduledBatch?:any;
  sequenceId?:string;
  txType?: string;
  tokenType?:string;
  _signers?:number;
  _signersSigns?:number;
  // msgToSign?:string;
  // usdAmount?:number;
  // walletId?:number;
}

export const txType = {
  Normal: 'normal',
  Approve: 'approve',
  Mint: 'mint',
  MintErc20: 'minterc20'
}

export const tokenType = {
  nativeToken: 0,
  erc20Token: 1,
  nftToken: 2,
}

export interface signs {
  walletid: number;
  userid: number;
  status: number;
  transactionrequestid: number;
  sig: string;
  pubkey: string;
  displayName: string
  isMine?: boolean;
  approved?:boolean;
}

// export interface metData{
//   tx_category_type:string;
//   tx_sub_category_type:string;
//   tx_detail_title:string;
//   tx_detail_description:string;
//   destination_title:string;
//   destination_address:string;
//   comment:string;
//   comment_owner:string;
//   meta_asset_details:{
//       amount:number;
//       amount_usd:number;
//       coin:string;
//   }
// }

export interface TransactionRequestMetadata {
  id: number;
  transactionReqId:string;
  metadata:string;
}