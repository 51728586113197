import { Psbt as BCH_Psbt } from '../../bchpsbt';
import { Psbt as DOGE_Psbt } from '../../dogepsbt.js';
import { environment } from '../../../../../environments/environment';
import * as bitcoin from 'bitcoinjs-lib';
import Transportwebusb from "@ledgerhq/hw-transport-webusb";
import { LedgerService } from '../../ledger/ledger';
import Btc from "@ledgerhq/hw-app-btc";
import { getSigningPubkey } from '../trezorTxUtis'
import TrezorConnect from 'trezor-connect';
import { Guid } from "guid-typescript";

export async function signShieldTransaction(trezorTx, psbT, output, txReq) {
	try {
		let Coinname;
		let signerTx;
		let signer1;
		if (this.wallet.chain.toLowerCase() == "bch") {
			signer1 = BCH_Psbt.fromHex(psbT, { network: environment.btcNetwork });
			Coinname = "Bitcoin Cash";
		}
		else if (this.wallet.chain.toLowerCase() == "ltc") {
			signer1 = bitcoin.Psbt.fromHex(psbT);
			Coinname = "Litecoin";
		}
		else if (this.wallet.chain.toLowerCase() == "doge") {
			signer1 = DOGE_Psbt.fromHex(psbT);
			Coinname = "Dogecoin";
		}
		else {
			signer1 = bitcoin.Psbt.fromHex(psbT, { network: environment.btcNetwork });
			Coinname = "Bitcoin";
		}
		var walletkey = this.wallet.walletKeys;
		var objwalletkey = walletkey.find(x => x.ismine === true);
		let mypath = objwalletkey.path;
		//let isLedger = true;
		let isLedger = (objwalletkey.provider == 'ledger');
		if (isLedger) {
			/////////Ledger Start
			let transport = await Transportwebusb.create();
			await LedgerService.openApp(Coinname, transport);
			this.delay(2000)
			transport = await LedgerService.getNewTransport();
			if (Coinname == "Bitcoin") {
				let currentapp = await LedgerService.getCurrentApp(transport);
				let appversion = parseInt(currentapp.version.split(".").map((s) => s.padStart(2, "0")).join(""), 10);
				if (currentapp.name == "Bitcoin" && appversion > 20006) {
					return [{ "error": "Please Install and open Bitcoin Legacy App" }]
				}
			}
			const btc = new Btc(transport);
			// Make __CACHE method to public in psbt.d.ts.
			const newTx = signer1.__CACHE.__TX;
			// Find it is segwit or not

			let isSegwit = (this.wallet.chain.toLowerCase() == "bch" || this.wallet.chain.toLowerCase() == "doge") ? false : true;
			//const outtx = btc.splitTransaction(newTx.toHex(), isSegwit,null,true,additionals);
			const outtx = btc.splitTransaction(newTx.toHex(), isSegwit);
			const outputScript = btc.serializeTransactionOutputs(outtx).toString('hex');
			function buf2hex(buffer) {
				return [...new Uint8Array(buffer)]
					.map(x => x.toString(16).padStart(2, '0'))
					.join('');
			};
			const inputs = new Array<[any, number, string | null | undefined, number | null | undefined]>();
			const associatedKeysets = new Array<string>();
			
			for (let i = 0; i < trezorTx.inputs.length; i++) {
				let reedemscreept;
				if (isSegwit)
					reedemscreept = signer1.data.inputs[i].witnessScript;
				else
					reedemscreept = signer1.data.inputs[i].redeemScript;
				const txbuffer = signer1.data.inputs[i].nonWitnessUtxo;
				const vinUTXO = buf2hex(txbuffer);
				var vintx = btc.splitTransaction(vinUTXO, isSegwit);
				inputs.push([vintx, trezorTx.inputs[i].prev_index, buf2hex(reedemscreept), undefined]);
				associatedKeysets.push(mypath);
			}
			let sigHashType = this.wallet.chain.toLowerCase() == "bch" ? 0x41 : 0x01;
			const btcout = await btc.signP2SHTransaction({
				inputs: inputs,
				associatedKeysets: associatedKeysets,
				outputScriptHex: outputScript,
				lockTime: 0,
				segwit: this.wallet.chain.toLowerCase() == "bch" ? true : isSegwit,
				transactionVersion: signer1.version,
				sigHashType: sigHashType
			});
			for (let i = 0; i < btcout.length; i++) {
				let sig;
				if (isSegwit || this.wallet.chain.toLowerCase() == "bch") {
					sig = Buffer.from(btcout[i], 'hex');
				}
				else {
					let sighash = this.wallet.chain.toLowerCase() == "bch" ? "41" : "01";
					sig = Buffer.concat([Buffer.from(btcout[i], 'hex'), Buffer.from(sighash, 'hex')]);
				}

				let partialSig;
				partialSig = [
					{
						pubkey: getSigningPubkey(this.wallet, []),
						signature: sig,
					}
				];
				signer1.data.updateInput(i, { partialSig: partialSig });
				let verifyResult = signer1.validateSignaturesOfInput(i, partialSig[0].pubkey);
			}
			signerTx = { success: true };
			/////////Ledger End
		}
		else {
			//////// Trezor Start
			console.log("Trezor TX", trezorTx);
			signerTx = await TrezorConnect.signTransaction(trezorTx);
			console.log("Signing result " + JSON.stringify(signerTx))
			console.log("Trezor Sheild", trezorTx, signerTx);
			if (!signerTx.success) {
				if (signerTx.payload && signerTx.payload.error && signerTx.payload.error == 'Pubkey not found in multisig script') {
					return [{ "error": "You might be signing with an incorrect trezor device!" }]
				}
				if (signerTx.payload.code == 'Device_CallInProgress') {
					TrezorConnect.cancel();
					return [{ "error": 'Device progress resetted! Try again now.' }]
				}
				if (signerTx.payload.code == 'Method_Interrupted') {
					return;
				}
				return [{ "error": signerTx.payload.code }]
			}
			this.logger.info(psbT + " " + signerTx, signerTx)
			for (let i = 0; i < signerTx.payload.signatures.length; i++) {
				let sighash = this.wallet.chain.toLowerCase() == "bch" ? "41" : "01";
				const partialSig = [
					{
						pubkey: getSigningPubkey(this.wallet, []),
						signature: Buffer.from(signerTx.payload.signatures[i] + sighash, "hex"),
					}
				];
				signer1.data.updateInput(i, { partialSig: partialSig });
				let verifyResult = signer1.validateSignaturesOfInput(i, partialSig[0].pubkey);
				this.logger.info("Verify result " + verifyResult)
				if (!verifyResult) {
					return [{ "error": "You might be signing with an incorrect trezor device!" }]
				}
			}
		}

		let sequenceId = Guid.create().toString();
		if (txReq) {
			if ('sequenceId' in txReq) {
				sequenceId = txReq['sequenceId'] as string;
			}
		}

		let apiResult = await this.httpService.addNewMultisigTransaction(this.wallet.id, {
			sequenceId: sequenceId,
			walletid: this.wallet.id,
			raw: { "txHex": psbT, signature: signer1.toBase64(), trezorTx: trezorTx },
			pubkey: "",
			destinationAddress: output.address,
			fromAddress: this.wallet.address,
			amount: output.value,
			chain: this.wallet.chain,
			asset: this.wallet.coin,
			type: 1
		}).toPromise()
		return [signerTx, apiResult]
	} catch (e) {
		console.log(e);
		throw e;
	}
}