import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
// Components
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { ContentComponent } from './components/layout/content/content.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';
import { MyAccountComponent } from '../shared/components/my-account/my-account.component';
import { WalletConnectComponent } from '../shared/components/wallet-connect/wallet-connect.component';
// Header Elements Components
// Services
import { LayoutService } from './services/layout.service';
import { LedgerService } from './helpers/ledger/ledger';
import { NavService } from './services/nav.service';
import { DateAgoPipe } from './components/pipes/date-ago.pipe';
import { NegtoposService } from './components/pipes/negtopos.service';
import { GetNamePipe, GetIconPipe, GetFeesUnitPipe } from './components/pipes/coins'
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { WalletCardComponent } from './components/wallet-card/wallet-card.component';
import { PendingTransactionsComponent } from './components/pending-transactions/pending-transactions.component';
import { AddressCardComponent } from './components/address-card/address-card.component';
import { DateRangeSelectionComponent } from './components/date-range-selection/date-range-selection.component';
import { UserInformationComponent } from './components/user-information/user-information.component';
import { RefillComponent } from './components/refill/refill.component';
import { AmountroundedPipe, toPrettyAmount } from './components/pipes/amountrounded.pipe';
import { NewGroupWalletComponent } from './components/new-group-wallet/new-group-wallet.component';
import { SearchPipe, SearchCoinPipe, SearchWalletPipe, SearchPolicyListPipe, SearchWalletListPipe, SearchWalletAddressPipe, historySearchPipe, consolidationRuleSearchPipe, teamsListSearchPipe, fromToDatePipe, usersListSearchPipe, defaultImagePipe, pendingSearchPipe, refillSearchPipe, SearchWalletStakingPipe, SearchAssetsStakingPipe, SearchRulesListPipe } from './components/pipes/search.pipe';
import { PoliciesListComponent } from './components/policies-list/policies-list.component';
import { AddPolicyComponent } from './components/add-policy/add-policy.component';
import { PolicyTypeFilterPipe, WalletTypeFilterPipe, actionsFilterPipe, WalletTypeSortPipe, refillFilterPipe } from './components/pipes/policy-type-filter-pipe';
import { TeamTypeFilterPipe } from './components/pipes/team-type-filter-pipe';
import { ProfileComponent } from './components/profile/profile.component';
import { TagifyModule } from 'ngx-tagify';

import { SignerUserListComponent } from './components/signer-user-list/signer-user-list.component';
import { WalletKeyCardPageComponent } from './components/wallet-key-card-page/wallet-key-card-page.component';
import { DatePipe } from '@angular/common';
import { InviteMembersComponent } from './components/invite-members/invite-members.component';
import { ReceiveAssetsComponent } from './components/receive-assets/receive-assets.component';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { ClipboardModule } from 'ngx-clipboard';
import { SendAssetsComponent } from './components/send-assets/send-assets.component';
import { HotWalletComponent } from './components/hot-wallet/hot-wallet.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { paginationTOFrom } from './components/pipes/pagination_from_to'
import { AmlComponent } from './components/aml/aml.component';
import { StakingApprovalPopupComponent } from './components/staking-approval-popup/staking-approval-popup.component'
import { StakingClamingRewardsComponent } from './components/staking-claming-rewards/staking-claming-rewards.component';
import { CopyAddressComponent } from './components/copy-address/copy-address.component';
import { AppDetailsComponent } from './components/app-details/app-details.component';
import { TrezorWebUSBComponent } from './components/trezor-webusb/trezor-webusb.component';
import { ConsolidationRuleViewComponent } from './components/consolidation-rule-view/consolidation-rule-view.component';
import { ConsolidationRuleAddComponent } from './components/consolidation-rule-add/consolidation-rule-add.component';
import { GenerateApiKeyComponent } from './components/generate-api-key/generate-api-key.component';
import { NotificationEmailComponent } from './components/notification-email/notification-email.component';
import { AddTeam } from './components/add-team/add-team.component';
import { AddTeamList } from './components/add-team-list/add-team-list.component';
import { AddViewPolicyManagerComponent } from './components/add-view-policy-manager/add-view-policy-manager.component';
import { AddMemberComponent } from './components/add-member/add-member.component';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { EnableMobileComponent } from './components/enable-mobile/enable-mobile.component';
import { StakingV2PopupComponent } from './components/staking-v2-popup/staking-v2-popup.component';
import { ValidatorCardComponent } from './components/validator-card/validator-card.component';
import { WalletAssetCardComponent } from './components/wallet-asset-card/wallet-asset-card.component';
import { TravelRuleComponent } from './components/travel-rule/travel-rule.component';

import { TravelRuleSendComponent } from './components/travel-rule-send/travel-rule-send.component';
import { EthValidatorsListComponent } from './components/eth-validators-list/eth-validators-list.component';
import { EventService } from "./services/events";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: false,
};

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    TapToTopComponent,
    MyAccountComponent,
    WalletConnectComponent,
    DateAgoPipe,
    GetNamePipe,
    GetIconPipe,
    GetFeesUnitPipe,
    toPrettyAmount,
    paginationTOFrom,
    NegtoposService,
    WalletCardComponent,
    PendingTransactionsComponent,
    AddressCardComponent,
    DateRangeSelectionComponent,
    UserInformationComponent,
    RefillComponent,
    AmountroundedPipe,
    NewGroupWalletComponent,
    SearchPipe,
    SearchCoinPipe,
    SearchWalletPipe,
    PoliciesListComponent,
    AddPolicyComponent,
    PolicyTypeFilterPipe,
    WalletTypeSortPipe,
    SearchPolicyListPipe,
    ProfileComponent,
    SignerUserListComponent,
    WalletKeyCardPageComponent,
    InviteMembersComponent,
    ReceiveAssetsComponent,
    TrezorWebUSBComponent,
    SendAssetsComponent,
    SearchWalletListPipe,
    SearchRulesListPipe,
    SearchWalletAddressPipe,
    WalletTypeFilterPipe,
    actionsFilterPipe,
    HotWalletComponent,
    historySearchPipe,
    consolidationRuleSearchPipe,
    AmlComponent,
    StakingApprovalPopupComponent,
    StakingClamingRewardsComponent,
    CopyAddressComponent,
    AppDetailsComponent,
    ConsolidationRuleViewComponent,
    ConsolidationRuleAddComponent,
    GenerateApiKeyComponent,
    NotificationEmailComponent,
    AddTeam,
    AddTeamList,
    AddViewPolicyManagerComponent,
    AddMemberComponent,
    teamsListSearchPipe,
    TeamTypeFilterPipe,
    fromToDatePipe,
    UserManagementComponent,
    EnableMobileComponent,
    usersListSearchPipe,
    defaultImagePipe,
    refillFilterPipe,
    pendingSearchPipe,
    refillSearchPipe,
    StakingV2PopupComponent,
    ValidatorCardComponent,
    WalletAssetCardComponent,
    SearchWalletStakingPipe,
    SearchAssetsStakingPipe,
    TravelRuleSendComponent,
    TravelRuleComponent,
    EthValidatorsListComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    Ng2SearchPipeModule,
    MatAutocompleteModule,
    PerfectScrollbarModule,
    TagifyModule.forRoot(),
    ClipboardModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxSkeletonLoaderModule.forRoot(),
  ],
  providers: [
    NavService,
    LayoutService,
    LedgerService,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    DatePipe,
  ],
  exports: [
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    TapToTopComponent,
    HeaderComponent,
    DateAgoPipe,
    GetNamePipe,
    GetIconPipe,
    GetFeesUnitPipe,
    toPrettyAmount,
    paginationTOFrom,
    NegtoposService,
    Ng2SearchPipeModule,
    MatAutocompleteModule,
    PendingTransactionsComponent,
    WalletCardComponent,
    AddressCardComponent,
    DateRangeSelectionComponent,
    MyAccountComponent,
    WalletConnectComponent,
    UserInformationComponent,
    RefillComponent,
    AmountroundedPipe,
    NewGroupWalletComponent,
    PoliciesListComponent,
    AddPolicyComponent,
    PolicyTypeFilterPipe,
    WalletTypeSortPipe,
    SearchPolicyListPipe,
    ProfileComponent,
    SignerUserListComponent,
    WalletKeyCardPageComponent,
    InviteMembersComponent,
    ReceiveAssetsComponent,
    TrezorWebUSBComponent,
    SendAssetsComponent,
    ClipboardModule,
    SearchWalletListPipe,
    SearchRulesListPipe,
    SearchWalletAddressPipe,
    WalletTypeFilterPipe,
    actionsFilterPipe,
    HotWalletComponent,
    historySearchPipe,
    consolidationRuleSearchPipe,
    AmlComponent,
    StakingApprovalPopupComponent,
    StakingClamingRewardsComponent,
    CopyAddressComponent,
    AppDetailsComponent,
    GenerateApiKeyComponent,
    ConsolidationRuleViewComponent,
    ConsolidationRuleAddComponent,
    GenerateApiKeyComponent,
    NotificationEmailComponent,
    AddTeam,
    AddTeamList,
    AddViewPolicyManagerComponent,
    AddMemberComponent,
    teamsListSearchPipe,
    TeamTypeFilterPipe,
    fromToDatePipe,
    UserManagementComponent,
    EnableMobileComponent,
    usersListSearchPipe,
    defaultImagePipe,
    refillFilterPipe,
    pendingSearchPipe,
    refillSearchPipe,
    StakingV2PopupComponent,
    ValidatorCardComponent,
    WalletAssetCardComponent,
    TravelRuleSendComponent,
    TravelRuleComponent,
    EthValidatorsListComponent
  ],
})
export class SharedModule { }