<div class="card m-0" *ngIf="component_for == 'Whitelist_Policy' && risk_factor_type != 'invalid'">
    <div class="card-body-border form-control" style="padding: 0.375rem 0.75rem;">
        <div class="d-flex justify-content-between">
            <div>
                <span class="mr-1">Compliance :</span>
                <span *ngIf="!loading" class="status text-capitalize" [ngClass]="[risk_factor_type=='risk'?'risk':'',risk_factor_type=='warning'?'warning':'',risk_factor_type=='pass'?'pass':'']">
                    {{ risk_factor_type }}
                    <i *ngIf="risk_factor_type=='risk' || risk_factor_type=='warning'" class="icon-alert" style="font-size: 12px!important;"></i>
                    <i *ngIf="risk_factor_type=='pass'" class="icon-check" style="font-size: 14px!important; vertical-align: middle;"></i>
                </span>
            </div>
            <span class="loader" *ngIf="loading"></span>
            <span *ngIf="!loading && (risk_factor_type!='pass')" (click)="open(aml_details)" class="btn-link text-muted text-underline cursor-pointer">View</span>
        </div>
    </div>
</div>
<a href="javascript:void(0);" *ngIf="component_for == 'aml-popup'" (click)="open(txAML)"
    class="text-underline font-weight-bold"
    class="pill text-capitalize" [ngClass]="{ 'danger': risk_factor.trmStatus === 'high', 'warning': risk_factor.trmStatus === 'medium', 'success': risk_factor.trmStatus === 'low'}">{{risk_factor.trmStatus}}</a>
<ng-template #txAML let-modal>
    <div class="modal-content">
        <div class="modal-header border-b">
            <h2 class="modal-title"><i class="icon-alert text-dark mr-2"></i>Risk Factor</h2>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <div class="">
                        <label class="text-dark font-size-14 font-weight-bold mb-1 ml-1">Network</label>
                        <div class="card mb-2 border">
                            <div class="card-body align-items-center" [ngClass]="card_padding">
                                <div class="media border-after-xs align-items-center px-2 my-2">
                                    <div class="d-flex flex-column text-left">
                                        <span
                                            class="network {{ chain.toLowerCase() }} font-size-14 font-weight-bold px-1 text-dark">
                                            {{ chain | getName }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <label class="text-dark font-size-14 font-weight-bold mb-1 ml-1">Address</label>
                            <div class="card mb-2 border">
                                <div class="card-body align-items-center" [ngClass]="card_padding">
                                    <div class="media border-after-xs align-items-center px-2 my-2">
                                        <div class="d-flex flex-column text-left">
                                            <span class="font-size-14 text-break text-dark">
                                                {{ address }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="text-center font-size-16 font-weight-bold mt-3 mb-2">This is a <span class="font-size-16 font-weight-bold" [ngClass]="{'text-red':  trm_data.trmStatus?.toLowerCase() === 'high', 'text-secondary' : trm_data.trmStatus?.toLowerCase() === 'medium', 'text-primary' : trm_data.trmStatus?.toLowerCase() === 'low' }">{{trm_data.trmStatus.toLowerCase()}} risk</span> transaction</p>
                    <div class="d-flex justify-content-center align-items-center">
                        <span class="font-size-20 font-weight-bold text-black">{{ totalAmount | amountToPretty : 5 }} {{trm_data.coin}}</span>
                        <span>&nbsp;≈&nbsp;</span>
                        <span class="font-size-16 text-dark">{{ total | amountToPretty : 2 }} USD</span>
                    </div>
                    <div class="d-flex my-3 px-5" style="justify-content: space-evenly;">
                        <span class="legend font-size-12 low">Low Risk</span>
                        <span class="legend font-size-12 medium">Medium Risk</span>
                        <span class="legend font-size-12 high">High Risk</span>
                    </div>
                    <div class="card">
                        <h2 class="font-size-14">Alerts</h2>
                        <div class="table-responsive border mb-4">
                            <table class="table font-size-12">
                                <thead>
                                    <tr>
                                        <th scope="col" class="text-dark text-left">Rule Name</th>
                                        <th scope="col" class="text-dark text-center">Status</th>
                                        <th scope="col" class="text-dark text-right">Alert Uuid</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let amlData of trm_data._alearts">
                                        <td class="text-left">
                                            <div [ngClass]="{'text-red':  amlData.riskScoreLevelLabel?.toLowerCase() === 'high', 'text-secondary' : amlData.riskScoreLevelLabel?.toLowerCase() === 'medium', 'text-primary' : amlData.riskScoreLevelLabel?.toLowerCase() === 'low' }">
                                               <span class="font-size-12" *ngIf="trm_data._counterParties[0].displayName">{{ trm_data._counterParties[0].displayName }}</span> 
                                               <span class="font-size-12" *ngIf="!trm_data._counterParties[0].displayName">-</span> 
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <div class="d-block type">
                                                <span class="font-size-12">{{amlData.status}}</span>
                                            </div>
                                        </td>
                                        <td class="text-right cursor-copy">
                                            <div ngbTooltip="{{ amlData.uuid }}" (click)="copy_Address(amlData.uuid,copy);">
                                              <span class="font-size-12" #copy="ngbTooltip"  triggers="click" ngbTooltip="Copied">{{ amlData.uuid.substring(0,8) }}...{{ amlData.uuid.substring(amlData.uuid.length-3) }} </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="!trm_data._alearts.length">
                                        <td colspan="3" class="text-center">No Data</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <h2 class="font-size-14">Counterparties</h2>
                        <div class="table-responsive border">
                            <table class="table font-size-12">
                                <thead>
                                    <tr>
                                        <th scope="col" class="text-dark text-left">Category</th>
                                        <th scope="col" class="text-dark text-right">Entity name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let amlData of trm_data._counterParties">
                                        <td class="text-left">
                                            <div>
                                               <span class="font-size-12" *ngIf="amlData.category">{{ amlData.category }}</span> 
                                               <span class="font-size-12" *ngIf="!amlData.category" class="ml-4">-</span> 
                                            </div>
                                        </td>
                                        <td class="text-right">
                                            <div>
                                                <span class="font-size-12" *ngIf="amlData.displayName">{{ amlData.displayName }}</span> 
                                                <span class="font-size-12" *ngIf="!amlData.displayName" class="mr-4">-</span> 
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #aml_details let-modal>
    <div class="modal-content">
        <div class="modal-header border-b">
            <h2 class="modal-title"><i class="icon-alert text-dark mr-2"></i>Risk Factor</h2>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <div class="">
                        <label class="text-dark font-size-14 font-weight-bold mb-1 ml-1">Network</label>
                        <div class="card mb-2 border">
                            <div class="card-body align-items-center" [ngClass]="card_padding">
                                <div class="media border-after-xs align-items-center px-2 my-2">
                                    <div class="d-flex flex-column text-left">
                                        <span
                                            class="network  {{ chain }}  font-size-14 font-weight-bold px-1 text-dark ">
                                            {{ chain | getName }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <label class="text-dark font-size-14 font-weight-bold mb-1 ml-1">Address</label>
                            <div class="card mb-2 border">
                                <div class="card-body align-items-center" [ngClass]="card_padding">
                                    <div class="media border-after-xs align-items-center px-2 my-2">

                                        <div class="d-flex flex-column text-left">

                                            <span class="font-size-14 text-break text-dark">
                                                {{ address }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="text-center font-size-14 font-weight-bold my-3">Alert: Risky Address. The total risk associated is ${{ total | amountToPretty : 2 }}.</p>
                    <div class="d-flex my-3" style="justify-content: space-evenly;">
                        <span class="legend low">Low Risk</span>
                        <span class="legend medium">Medium Risk</span>
                        <span class="legend high">High Risk</span>
                    </div>
                    <div class="card">
                        <div class="table-responsive border">
                            <table class="table font-size-12">
                                <thead>
                                    <tr>
                                        <th scope="col " class="text-dark text-left">Category</th>
                                        <th scope="col" class="text-dark text-center">Type</th>
                                        <!-- <th scope="col" class="text-dark text-center">Score </th> -->
                                        <th scope="col" class="text-dark text-right">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let amlData of aml_data">
                                        <td class="text-left">
                                            <div [ngClass]="{'text-red':  amlData.categoryRiskScoreLevelLabel  === 'High', 'text-secondary' : amlData.categoryRiskScoreLevelLabel  === 'Medium', 'text-primary' : amlData.categoryRiskScoreLevelLabel  === 'Low' }">
                                                {{ amlData.category }}
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <div class="d-block type">
                                                {{ amlData.riskType }}
                                            </div>
                                        </td>
                                        <!-- <td class="text-center">
                                            <div [ngClass]="{'text-red':  amlData.categoryRiskScoreLevelLabel  === 'High', 'text-secondary' : amlData.categoryRiskScoreLevelLabel  === 'Medium', 'text-primary' : amlData.categoryRiskScoreLevelLabel  === 'Low' }">
                                                {{ amlData.categoryRiskScoreLevelLabel }}
                                            </div>
                                        </td> -->
                                        <td class="text-right">
                                            <div>
                                                ${{ amlData.totalVolumeUsd | amountToPretty : 2 }}
                                                <span placement="left" [ngbTooltip]="list">
                                                    <i class="icon-info" style="font-size: 12px!important;"></i>
                                                </span>
                                                <ng-template #list>
                                                    <div class="d-flex flex-column text-left">
                                                        <span>Incoming: ${{ amlData.incomingVolumeUsd | amountToPretty : 2 }}</span>
                                                        <span>Outgoing: ${{ amlData.outgoingVolumeUsd | amountToPretty : 2 }}</span>
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>