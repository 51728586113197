import { Output, SignedTransaction } from '../../../entities';
import { TransactionRequest } from '../../../entities/TransactionRequest';
import TransportWebHID from "@ledgerhq/hw-transport-webhid";
import { PublicKey, Transaction as solanaTransaction } from '@solana/web3.js';
import Solana from "@ledgerhq/hw-app-solana";
import { binary_to_base58 } from 'base58-js'
import { LedgerService } from '../../ledger/ledger';
import Swal from 'sweetalert2';
import { Guid } from "guid-typescript";

function errorHandler(err: any, objwalletkey) {
    if (err.message == 'Missing a parameter. Try enabling blind signature in the app') {
        Swal.fire({
            icon: 'error',
            title: 'Failed to sign transaction',
            text: 'Please make sure blind signing is enabled for Solana and Solana app is open in Ledger at the time of signing',
            showConfirmButton: false,
        })
    }
    else if (err.message == "Ledger device: UNKNOWN_ERROR (0x6a8d)") {
        Swal.fire({
            // position: 'top-end',
            icon: 'error',
            title: 'Failed to sign transaction!',
            text: "Allow unverified contracts from Ledger app setting.",
            showConfirmButton: false,
            // timer: 1500
        })
        try {
            this.segment.track("send-asset-transaction-sign-failed", this.authService.getUser)
                .then(() => console.log("Send asset transaction sign failed"));
        } catch (err) {
            this.logger.error(err, err);
        }
    }
    else if (err.name == "TransportOpenUserCancelled") {
        Swal.fire({
            // position: 'top-end',
            icon: 'error',
            title: 'Failed to sign transaction!',
            text: "Device connection error",
            showConfirmButton: false,
            // timer: 1500
        })
        try {
            this.segment.track("send-asset-transaction-sign-failed", this.authService.getUser)
                .then(() => console.log("Send asset transaction sign failed"));
        } catch (err) {
            this.logger.error(err, err);
        }
    } else {
        if ('response' in err && err.response.data.message) {
            if (err.response.data.message == "failed to send transaction: Transaction simulation failed: Error processing Instruction 0: custom program error: 0x1") {
                Swal.fire({
                    icon: 'error',
                    title: 'Failed to sign transaction!',
                    text: "Insuffucent Fund in this address " + objwalletkey[0].xpub,
                    showConfirmButton: false,
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Failed to sign transaction!',
                    text: err.response.data.message,
                    showConfirmButton: false,
                })

            }
        } else {
            Swal.fire({
                // position: 'top-end',
                icon: 'error',
                title: 'Failed to sign transaction!',
                text: err.message,
                showConfirmButton: false,
                // timer: 1500
            })
        }
        try {
            this.segment.track("send-asset-transaction-sign-failed", this.authService.getUser)
                .then(() => console.log("Send asset transaction sign failed"));
        } catch (err) {
            this.logger.error(err, err);
        }
    }
}

export async function signSolTransaction(output: Output, req: TransactionRequest, type: string) {
    if (output.value < 0.001) {
        return [{ "error": "Minimum tx amount should be 0.001" }];
    }
    let transport = await TransportWebHID.create();
    let objwalletkey = this.wallet.walletKeys.filter(item => item.ismine === true);
    try {
        let txParam: any = {}
        var txJSON;
        var txid;
        let CoinName = "Solana";
        let mypath = objwalletkey[0].path;
        const solana = new Solana(transport);
        let Address = await solana.getAddress(mypath);
        let address = binary_to_base58(Address.address);
        if (objwalletkey[0].xpub != address) {
            return [{ "error": "Wrong Ledger Device PubKey" }];
        }
        if ((req && req['actionType'] == 'travel-rule') || !req) {
            var respData = await this.httpService.getPrebuildTx({
                reqobj:
                {
                    nonce: 0,
                    output: output,
                    wallet: this.wallet,
                    type: type.toLowerCase(),
                }
            })
            var raw_tx = respData.txJson.msgs[0];
            var base64string = raw_tx;
            var buf = Buffer.from(base64string, 'base64'); // Ta-da
            let transaction = solanaTransaction.from(buf);
            var serializetx = transaction.serializeMessage();;
            let currentapp = await LedgerService.getCurrentApp(transport);
            if (currentapp.name != CoinName) {
                return [{ "error": "Please make sure Solana app is open in Ledger with blind signing enabled and try again" }]
            }
            const sintx = await solana.signTransaction(mypath || "44'/501'/0'", serializetx);
            transaction.addSignature(new PublicKey(objwalletkey[0].xpub), sintx.signature);
            let endocdeTransction = transaction.serialize({
                requireAllSignatures: false,
                verifySignatures: true,
            });
            var signedbase64 = endocdeTransction.toString('base64');
            await transport.close();

            let signTransactionResponse: SignedTransaction = {
                txBASE64: signedbase64,
                proposalAddress: respData.txJson.proposalAddress,
                solanaoutput: output,
                coinSymbol: respData.txOutputs?.coinSymbol
            };
            // if (req && req['actionType'] == 'travel-rule') signTransactionResponse['sequenceId'] = req['sequenceId'];
            let sequenceId = Guid.create().toString();
            signTransactionResponse['sequenceId'] = sequenceId;
            if (req) {
                if ('sequenceId' in req) {
                  signTransactionResponse['sequenceId'] = req['sequenceId']
                }
            }
            var sendresult = await this.httpService.SendTransaction(this.wallet.id, signTransactionResponse);
            sendresult = (sendresult)
            if (sendresult.status !== 200) {
                return [{ "error": sendresult }]
            }
            return "success";
        } else {
            let m = Number.parseInt(this.wallet.config.split("of")[0]);
            let n = this.wallet.config.split("of")[1];
            txid = req.id;
            txParam = (req.raw);
            let data =
            {
                "sig": txParam,
                "pubkey": objwalletkey[0].xpub
            }
            var respgetAprovetx = await this.httpService.GetapproveTxSol(JSON.stringify(data), this.wallet.id, txid);
            if (respgetAprovetx.status == 200) {

                let approvedata = {
                    "sig": txParam,
                    "pubkey": objwalletkey[0].xpub,
                    "base64": ""
                }
                var exebase64 = respgetAprovetx.data.data.base64;
                // @ts-ignore
                //const API_URL = clusterApiUrl(environment.sol_config.SOL.network);
                //var connection = new Connection(API_URL);
                var buf = Buffer.from(exebase64, 'base64'); // Ta-da
                let transactionold = solanaTransaction.from(buf);
                let transaction = new solanaTransaction();

                transaction.instructions = (transactionold.instructions);
                //let blockhashObj = await connection.getRecentBlockhash();
                //transaction.recentBlockhash = await blockhashObj.blockhash;
                transaction.recentBlockhash = await transactionold.recentBlockhash;
                transaction.feePayer = new PublicKey(objwalletkey[0].xpub);
                var serializetx = transaction.serializeMessage();
                const sintx = await solana.signTransaction(mypath || "44'/501'/0'", serializetx);
                transaction.addSignature(new PublicKey(objwalletkey[0].xpub), sintx.signature);
                let endocdeTransction = transaction.serialize({
                    requireAllSignatures: false,
                    verifySignatures: true,
                });
                var signedbase64 = endocdeTransction.toString('base64');
                await transport.close();
                approvedata.sig = binary_to_base58(sintx.signature);
                approvedata.base64 = signedbase64;
                var respApprove = await this.httpService.ApproveTx(JSON.stringify(approvedata), this.wallet.id, txid);
                if (respApprove.status == 200) {
                    return "success";
                }
            }
        }
    }
    catch (err) {
        console.error("signSolTransaction", err);
        transport.close();
        errorHandler.call(this, err, objwalletkey);
    }
}